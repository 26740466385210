import styled from "styled-components";
import { useGate, useStore } from "effector-react";

import {
  FormAuthTemplate,
  FormFields,
} from "shared/ui/templates/auth-form-template";
import { SubmitButton, InputField } from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";

import { loginApiErrorsByStatuses } from "shared/api";
import { ModalLink } from "@client-app/shared/lib/modal-routes";

import {
  $formSubmitting,
  $serverErrorStatus,
  signInForm,
  signInFormGate,
} from "../model";

export function SignInForm(): JSX.Element {
  useGate(signInFormGate);
  const isSubmitting = useStore($formSubmitting);
  const errorStatus = useStore($serverErrorStatus);

  return (
    <Form form={signInForm}>
      <FormAuthTemplate
        error={
          errorStatus &&
          (loginApiErrorsByStatuses[errorStatus] ?? "Unknown error")
        }
        header="Welcome Back!"
        subHeader="Please enter your email and password."
        renderContent={() => (
          <FormFields>
            <InputField
              name="email"
              type="email"
              label="Enter email"
              placeholder="Enter email"
            />
            <InputField
              name="password"
              type="password"
              label="Enter password"
              placeholder="Enter password"
            />
          </FormFields>
        )}
        renderAction={() => (
          <Actions>
            <SubmitButton isSubmitting={isSubmitting}>Sign In</SubmitButton>
            <ForgotPasswordButton>
              <ModalLink modal="forgot-password">Forgot password?</ModalLink>
            </ForgotPasswordButton>
          </Actions>
        )}
      />
    </Form>
  );
}

const ForgotPasswordButton = styled.div``;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  ${ForgotPasswordButton} {
    align-self: center;
    margin-top: 24px;
  }
`;
