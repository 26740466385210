import { useEffect } from "react";
import { renderRoutes } from "react-router-config";
import styled from "styled-components";
import { useStore } from "effector-react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { syncHistoryWithState } from "shared/lib/effector-router-params";

import { $viewer } from "@client-app/entities/viewer";
import { AppContext } from "shared/init";

import { noAuthRoutes, authRoutes } from "./routes";
import { ModalRoutes } from "./modal-routes";
import { FeedbackButton } from "../features/give-feedback";
import { init } from "./init";
import { setHistory } from "../../shared/config/histroy";

const history = createBrowserHistory();
setHistory(history);
syncHistoryWithState(history, authRoutes);

init();

history.listen(({ pathname, search }) => {
  // @ts-ignore
  window.gsc?.("trackPage", `${pathname}${search}`);
});

export function App(): JSX.Element {
  const user = useStore($viewer);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <AppContext>
      <Router history={history}>
        {user ? (
          renderRoutes(authRoutes)
        ) : (
          <>
            {renderRoutes(noAuthRoutes)}
            <ModalRoutes />
          </>
        )}
      </Router>
      <FeedBack />
    </AppContext>
  );
}

const FeedBack = styled(FeedbackButton)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1;
`;
