import { createEvent, createStore } from "effector";
import { persist } from "effector-storage/local";

import { Boat } from "shared/api/types";

type NoAuthUserBoat = Omit<Boat, "id" | "@id">;

export const $noAuthUserBoat = createStore<NoAuthUserBoat | null>(null);
export const noAuthUserBoatSelected = createEvent<NoAuthUserBoat>();

$noAuthUserBoat.on(noAuthUserBoatSelected, (_, boat) => boat);

persist({
  key: "userBoat",
  store: $noAuthUserBoat,
});
