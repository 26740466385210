import styled from "styled-components";
import { useMedia } from "shared/ui/styles/media";
import { BoatIcon, DocksIcon, ListIcon, ProfileIcon } from "shared/ui/icons";
import {
  NavigationMenu,
  NavigationMenuIcon as NavIcon,
  NavigationMenuItem,
} from "shared/ui/templates/authorized-page-template";
import { postClientLogout } from "shared/api";
import { logout } from "@client-app/entities/viewer";

import { DesktopHeader } from "./header.desktop";
import { MobileHeader } from "./header.mobile";

function HeaderComponent(props: {
  className?: string;
  isHero?: boolean;
}): JSX.Element {
  const { isDesktop } = useMedia();

  const navigation = (
    <NavigationMenu>
      <NavigationMenuItem to="/profile">
        <NavIcon as={ProfileIcon}></NavIcon> Profile
      </NavigationMenuItem>
      <NavigationMenuItem to="/dock-list">
        <NavIcon as={DocksIcon}></NavIcon> Docks
      </NavigationMenuItem>
      <NavigationMenuItem to="/boats">
        <NavIcon as={BoatIcon}></NavIcon> Boats
      </NavigationMenuItem>
      <NavigationMenuItem to="/reservations-list">
        <NavIcon as={ListIcon}></NavIcon>
        Reservations
      </NavigationMenuItem>
    </NavigationMenu>
  );

  async function handleSignOut() {
    await postClientLogout({});
    logout();
  }

  return isDesktop ? (
    <DesktopHeader
      className={props.className}
      navigation={navigation}
      onSignOutClick={handleSignOut}
      isHero={props.isHero}
    />
  ) : (
    <MobileHeader
      className={props.className}
      navigation={navigation}
      onSignOutClick={handleSignOut}
      isHero={props.isHero}
    />
  );
}

export const Header = styled(HeaderComponent)``;
