import React, { Ref, useRef } from "react";
import styled, { css } from "styled-components";

import { mediaPhone } from "shared/ui/styles/media";
import { Button } from "shared/ui/button";
import { AddImageIcon, UploadIcon, DeleteIcon } from "shared/ui/icons";

import { useUnit } from "effector-react";
import { FormControl, FormHelperText } from "@mui/material";
import Cropper from "react-easy-crop";
import { useField } from "shared/lib/form";

import {
  $crop,
  $selectedImageSrc,
  $zoom,
  cropChanged,
  croppedAreaChanged,
  fileSelected,
  imageDeleted,
  zoomChanged,
} from "./image.model";

export function BoatImageField(): JSX.Element {
  const { isShowError, error } = useField({ name: "image" });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [imageSrc, crop, zoom] = useUnit([$selectedImageSrc, $crop, $zoom]);

  return (
    <Root>
      <Control error={isShowError}>
        {!imageSrc ? (
          <HiddenFileInput inputRef={fileInputRef}>
            <UploadNewImage>
              <div>
                <Upload as={UploadIcon} />
                <Formats>Files supported: JPG, PNG</Formats>
              </div>
              <div>
                <UploadButton
                  onClick={() => {
                    // @ts-ignore
                    fileInputRef.current.click();
                  }}
                >
                  <AddImage as={AddImageIcon} />
                  Browse files
                </UploadButton>
                <SizeInfo>Maximum size {2}Mb</SizeInfo>
              </div>
            </UploadNewImage>
          </HiddenFileInput>
        ) : (
          <>
            <CropperContainer>
              <DeleteImage onClick={() => imageDeleted()} />
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={16 / 9}
                onCropChange={cropChanged}
                onCropComplete={(_, croppedAreaPixels) =>
                  croppedAreaChanged(croppedAreaPixels)
                }
                onZoomChange={zoomChanged}
              />
            </CropperContainer>
          </>
        )}
        <FormHelperText id="my-helper-text">
          {isShowError && <div>{error}</div>}
        </FormHelperText>
      </Control>
    </Root>
  );
}

const Control = styled(FormControl)`
  width: 100%;
`;

const CropperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 252px;
`;

const Upload = styled.div`
  width: 88px;
  height: 64px;
`;

const Formats = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #0a1128;
`;

const AddImage = styled.div`
  width: 24px;
  height: 24px;
`;

const DeleteImage = styled(DeleteIcon)`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 10;
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

const UploadButton = styled(Button).attrs({
  type: "button",
})`
  width: 180px;
  ${AddImage} {
    margin-right: 10px;
  }
`;

const SizeInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #969899;
`;

const UploadNewImage = styled.div<{ invalid?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  aspect-ratio: 16/9;
  cursor: pointer;
  padding: 48px 0 40px;
  background: #f7f9fc;
  border: 1px dashed #2F3895;
  border-radius: 16px;

  ${(props) =>
    props.invalid &&
    css`
      border-style: solid;
      border-color: #de2016;
    `}}

  & > * {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  

  ${mediaPhone} {
    height: 281px;
    padding: 32px 24px;
  }
`;

const Root = styled.div`
  margin-bottom: 24px;
`;

function HiddenFileInput(props: {
  children: React.ReactNode;
  inputRef: Ref<HTMLInputElement>;
  disabled?: boolean;
}) {
  async function handleFileSelect(event: React.FormEvent<HTMLInputElement>) {
    if (event.currentTarget?.files?.[0]) {
      const file = event.currentTarget?.files?.[0];
      await fileSelected(file);
    }
  }

  return (
    <HiddenFileInputRoot>
      <input
        disabled={props.disabled}
        ref={props.inputRef}
        type="file"
        accept="image/jpeg, image/jpg, image/png"
        onChange={handleFileSelect}
      />
      {props.children}
    </HiddenFileInputRoot>
  );
}

const HiddenFileInputRoot = styled.label`
  display: block;
  input[type="file"] {
    width: 0;
    height: 0;
    position: absolute;
  }
`;
