import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { createGate } from "effector-react";

import { isFutureValidation, isPastValidation } from "entities/reservations";
import { reservationCanceled } from "@client-app/features/cancel-reservation";
import { getListFromApi, getReservationCollection } from "shared/api";
import { Reservation } from "shared/api/types";
import { spreadListDataData } from "shared/lib/effector-paginatoin";

type Filter = "future" | "past";

export const pageGate = createGate();

export const $clientReservations = createStore<Reservation[]>([]);
export const $filter = createStore<Filter>("future");
export const $reservationsList = combine(
  $clientReservations,
  $filter,
  (reservations, filter) => {
    if (filter === "future") {
      return reservations.filter(isFutureValidation);
    } else {
      return reservations.filter(isPastValidation);
    }
  }
);

export const filterChanged = createEvent<Filter>();

const getClientsReservationsFx = attach({
  // @ts-ignore
  effect: createEffect(getListFromApi(getReservationCollection)),
  mapParams: () => ({
    query: { itemsPerPage: 1000 },
  }),
});

$filter
  .on(filterChanged, (_, filterValue) => filterValue)
  .reset(pageGate.close);

spreadListDataData({
  $items: $clientReservations,
  effect: getClientsReservationsFx,
});

sample({
  clock: [pageGate.open, reservationCanceled],
  target: getClientsReservationsFx,
});
