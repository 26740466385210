import styled from "styled-components";
import { useGate, useStore } from "effector-react";
import { Box } from "@mui/material";

import {
  InputField,
  PhoneField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";
import { mediaPhone } from "shared/ui/styles/media";

import { ReactComponent as SuccessIcon } from "./success.svg";
import {
  editClientForm,
  $formSubmitting,
  Gate,
  $showSuccessMessage,
  $buttonSaveDisable,
} from "../model";

export function EditProfileForm(): JSX.Element {
  useGate(Gate);
  const showSuccessMessage = useStore($showSuccessMessage);
  const buttonSaveDisable = useStore($buttonSaveDisable);
  const isSubmitting = useStore($formSubmitting);
  return (
    <FormContent as={Form} form={editClientForm}>
      <FormFields>
        <InputField
          name="firstName"
          type="string"
          label="First name"
          placeholder="First Name"
        />
        <InputField
          name="lastName"
          type="string"
          label="Last Name"
          placeholder="Last Name"
        />
        <PhoneField name="phone" label="Phone Number" />
        <InputField
          name="email"
          type="email"
          label="Email"
          placeholder="Email"
        />
      </FormFields>
      <Actions>
        <SubmitButton disabled={buttonSaveDisable} isSubmitting={isSubmitting}>
          Save changes
        </SubmitButton>
        {showSuccessMessage && (
          <Success>
            <SuccessIcon />
            Your personal information was updated and saved
          </Success>
        )}
      </Actions>
    </FormContent>
  );
}

const Success = styled.div`
  display: inline-flex;
  gap: 10px;
  background: #e2eee2;
  border-radius: 600px;
  font-size: 14px;
  line-height: 15px;
  color: #549854;
  padding: 11px 40px 11px 14px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  ${mediaPhone} {
    flex-direction: column;
  }
`;

const FormFields = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  ${mediaPhone} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;
const FormContent = styled.div`
  ${FormFields} {
    margin-bottom: 32px;
  }
  ${SubmitButton} {
    width: 180px;
    ${mediaPhone} {
      width: 100%;
    }
  }
`;
