import { attach, createEffect, createStore } from "effector";

import { apiRequestFx } from "shared/api";

import { $viewer } from "../viewer";

export type PaymentsMethod = {
  id: number;
  cardLast4: string;
  cardBrand: string;
};
export const $paymentsMethods = createStore<PaymentsMethod[]>([]);
export const getSavedPaymentsMethodsFx = attach({
  source: $viewer,
  effect: async (client) => {
    const { paymentMethods }: { paymentMethods: PaymentsMethod[] } =
      await apiRequestFx({
        method: "GET",
        path: `/api/clients/${client?.id}`,
      });

    return paymentMethods;
  },
});
export const deletePaymentMethodFx = createEffect(async (id: number) => {
  await apiRequestFx({
    method: "DELETE",
    path: `/api/payment-methods/${id}`,
  });
});
