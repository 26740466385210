import { useStore } from "effector-react";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import styled from "styled-components";

import { $remainingTime, secondsForPayment, timeUp } from "../model";
import { useMedia } from "../../../../shared/ui/styles/media";

function TimerComponent(props: { className?: string }) {
  const remainingTime = useStore($remainingTime);
  const { isPhone } = useMedia();

  const size = isPhone ? 88 : 72;

  return (
    <TimerRoot className={props.className}>
      <CountdownCircleTimer
        isPlaying={true}
        initialRemainingTime={remainingTime}
        onComplete={() => timeUp()}
        duration={secondsForPayment}
        colors={["#5E88EF", "#5E88EF"]}
        colorsTime={[secondsForPayment, secondsForPayment]}
        rotation="counterclockwise"
        strokeWidth={4}
        size={size}
      >
        {({ remainingTime }) => {
          const minutes = Math.floor(remainingTime / 60);
          const seconds = (remainingTime % 60).toString().padStart(2, "0");
          return (
            <Time>
              {minutes}:{seconds}
            </Time>
          );
        }}
      </CountdownCircleTimer>
    </TimerRoot>
  );
}

export const Timer = styled(TimerComponent)``;

const TimerRoot = styled.div`
  display: inline-block;
`;

const Time = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #5e88ef;
`;
