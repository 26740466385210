import styled from "styled-components";
import { useHistory } from "react-router";
import { useStore } from "effector-react";

import {
  ForgotPasswordForm,
  $formSubmittedEmail,
  ForgotPasswordFormSuccessModal,
} from "features/password-recovery";
import { AuthModalTemplate } from "@client-app/shared/ui/templates/auth-modal-template";
import { mediaPhone } from "shared/ui/styles/media";

interface Props {
  onClose(): void;
  isOpened: boolean;
}

export function ForgotPasswordModal(props: Props): JSX.Element {
  const formSubmittedEmail = useStore($formSubmittedEmail);
  const history = useHistory();
  return (
    <>
      <AuthModalTemplate
        isOpened={props.isOpened && !formSubmittedEmail}
        onClose={props.onClose}
        renderContent={() => (
          <Content>
            <ForgotPasswordForm />
          </Content>
        )}
      />

      <ForgotPasswordFormSuccessModal
        isOpened={props.isOpened && !!formSubmittedEmail}
        onClose={() => history.push("/?m=login")}
      />
    </>
  );
}

const Content = styled.div`
  margin-top: 128px;

  ${mediaPhone} {
    margin-top: 56px;
  }
`;
