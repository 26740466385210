import dayjs, { Dayjs } from "dayjs";

import { convertDateToApi } from "./dayjs-ext";

export {
  dayjs,
  removeTimeZone,
  convertDateToApi,
  convertToApi,
} from "./dayjs-ext";
export { time, displayTime } from "./time";

export function getFirstDayOfMonth(date?: string | Dayjs): string {
  return convertDateToApi(dayjs(date).set("date", 1));
}
