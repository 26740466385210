import { attach, createEvent, sample, createStore } from "effector";

import { createApiErrorStatusStore, deleteBoatItem } from "shared/api";

import { boatDeleted } from "@client-app//entities/boats";

export const deleteBoat = createEvent<number>();

const deleteBoatFx = attach({
  effect: deleteBoatItem,
  mapParams: (boatId: number) => ({ path: { id: boatId.toString() } }),
});

sample({
  clock: deleteBoat,
  target: deleteBoatFx,
});

export const $isErrorBoatDeleteModalOpened = createStore(false);
export const closeErrorModal = createEvent();
export const $serverErrorStatus = createApiErrorStatusStore(deleteBoatFx);
$isErrorBoatDeleteModalOpened.on(closeErrorModal, () => false);
sample({
  source: $serverErrorStatus,
  fn: (errorStatus) => errorStatus === 400,
  target: $isErrorBoatDeleteModalOpened,
});

sample({
  clock: deleteBoatFx.done.map(({ params }) => params),
  target: boatDeleted,
});
