import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

import LocationIcon from "./marker-icon.svg";

export interface Props {
  coords: {
    lat: number;
    lng: number;
  };
}

export function MapWithMarker(props: Props): JSX.Element {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.RAZZLE_GOOGLE_MAP_API_KEY,
  });

  if (!isLoaded) return null;

  return (
    <>
      <GoogleMap
        zoom={15}
        center={props.coords}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
      >
        <Marker position={props.coords} icon={LocationIcon} />
      </GoogleMap>
    </>
  );
}
