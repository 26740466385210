import { createForm } from "effector-react-form";
import { createGate } from "effector-react";
import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
  split,
} from "effector";
import { Dayjs } from "dayjs";
import { and, or, pending } from "patronum";

import {
  createApiErrorStatusStore,
  fromApi,
  getReservationSchedule,
} from "shared/api";
import { Boat, Dock, Reservation } from "shared/api/types";
import {
  convertDateToApi,
  convertToApi,
  getFirstDayOfMonth,
  removeTimeZone,
} from "shared/lib/dayjs-ext";
import {
  $clientMainBoat,
  $clientsBoats,
  $noAuthUserBoat,
  boatAdded,
  getBoatByIRI,
} from "@client-app/entities/boats";
import { $hasAuth } from "@client-app/entities/viewer";
import {
  timeSlotIsAlreadyTakenModalActions,
  someWrongModalActions,
} from "@client-app/entities/reservation";
import { formField } from "shared/lib/form";
import { getMaxDayDuration } from "entities/docks";

import {
  getReservationWithClientPriceFx,
  ReservationPrice,
} from "entities/reservations";

import { clientCreateReservationFx } from "@client-app/entities/reservation/api";

type PriceInfo = ReservationPrice;

export type BaseReservationInfo = {
  boatId: number;
  timeFrom: string;
  duration: number;
};

export type ReservationApiInput = {
  boat: string;
  dock: string;
  timeFrom: string;
  timeTo: string;
};

export const formGate = createGate();

export const $dock = createStore<Dock | null>(null);
export const $monthStartDate = createStore(getFirstDayOfMonth());

export const $availableDates = createStore<
  Record<string, { from: string; to: string }[]>
>({});
export const $priceInfo = createStore<PriceInfo | null>(null);

export const reserveDockForm = createForm({
  initialValues: {
    boatId: null,
    date: "",
    time: null,
    duration: null,
  },
  onSubmit: () => formSubmitted(),
});

const $selectedBoatIRI = formField(reserveDockForm, "boatId");
export const $duration = formField(reserveDockForm, "duration");
export const $selectedDate = formField(reserveDockForm, "date");
export const $selectedTime = formField(reserveDockForm, "time");
export const $maxAvailableDurations = $dock.map((dock) => {
  return dock ? getMaxDayDuration(dock) : 0;
});

export const $availableTimes = combine(
  $availableDates,
  $selectedDate,
  (availableDates, selectedDate) =>
    availableDates[convertDateToApi(selectedDate)] ?? []
);

const $selectedBoat = combine(
  $selectedBoatIRI,
  $clientsBoats,
  $hasAuth,
  $noAuthUserBoat,
  (boatIRI, boats, hasAuth, noAuthUserBoat) =>
    hasAuth ? getBoatByIRI(boats, boatIRI) : noAuthUserBoat
);
const $dockId = $dock.map((dock) => dock?.id ?? null);
const $dockIRI = $dock.map((dock) => dock?.["@id"] ?? null);
const $boatId = $selectedBoat.map((boat) => boat?.id ?? null);
const $loa = $selectedBoat.map((boat) => boat?.loa);
const $beam = $selectedBoat.map((boat) => boat?.beam);
const $timeFrom = $selectedTime.map((time) =>
  time?.from ? convertToApi(removeTimeZone(time.from)) : null
);
const $timeTo = $selectedTime.map((time) =>
  time?.to ? convertToApi(removeTimeZone(time.to)) : null
);

const $priceRequestData = combine({
  dock: $dockIRI,
  loa: $loa,
  beam: $beam,
  timeFrom: $timeFrom,
  timeTo: $timeTo,
});

export const $reservation = combine({
  dock: $dockIRI,
  boat: $selectedBoatIRI,
  timeFrom: $timeFrom,
  timeTo: $timeTo,
});

export const selectedMonthChanged = createEvent<Dayjs>();
export const setReservationInfo = createEvent<BaseReservationInfo>();
export const reservationCreated = createEvent<Reservation>();
export const formSubmitted = createEvent();
export const loginAndConfirmBookingClicked = createEvent();
const reservationIsNotFitted = createEvent<ReservationApiInput>();
const selectBoat = reserveDockForm.setValue.prepend((boat: Boat) => ({
  field: "boatId",
  value: boat?.["@id"] ?? null,
}));

const getAvailableItems = attach({
  effect: createEffect(fromApi(getReservationSchedule)),
  mapParams: (query) => ({
    query,
  }),
});

const getPriceFx = attach({
  effect: getReservationWithClientPriceFx,
});

// @ts-ignore
const createReservationFx = attach({
  effect: clientCreateReservationFx,
});

export const tryToCreateReservationFx = createEffect(
  async (reservation: ReservationApiInput) => {
    await createReservationFx(reservation);
  }
);

$monthStartDate.on(selectedMonthChanged, (_, date) => getFirstDayOfMonth(date));
$availableDates.on(getAvailableItems.doneData, (_, items) => items);
$priceInfo
  .reset(getPriceFx)
  .on(getPriceFx.doneData, (_, priceInfo) => priceInfo);

sample({
  // @ts-ignore
  clock: [formGate.open, $clientsBoats],
  source: $clientMainBoat,
  filter: $selectedBoat.map((boat) => !boat),
  target: selectBoat,
});

const $getAvailableItemsData = combine({
  dockId: $dockId,
  boatId: $boatId,
  loa: $loa,
  hours: $duration,
  monthStartDate: $monthStartDate,
});

sample({
  clock: [$getAvailableItemsData, reservationIsNotFitted],
  source: $getAvailableItemsData,
  filter: and($dockId, or($boatId, $loa), $duration, $monthStartDate),
  target: getAvailableItems,
});

sample({
  clock: $availableDates,
  source: $selectedDate,
  filter: (date, availableDates) => !availableDates[convertDateToApi(date)],
  target: reserveDockForm.setValue.prepend(() => ({
    field: "date",
    value: null,
  })),
});

sample({
  clock: $availableTimes,
  source: $selectedTime,
  target: reserveDockForm.setValue.prepend(() => ({
    field: "time",
    value: null,
  })),
});

sample({
  source: boatAdded,
  target: selectBoat,
});

sample({
  // @ts-ignore
  clock: setReservationInfo,
  target: reserveDockForm.$values,
});

sample({
  clock: $priceRequestData,
  filter: and($loa, $beam, $timeTo, $timeFrom, $dockIRI),
  target: getPriceFx,
});

sample({
  // @ts-ignore
  clock: formSubmitted,
  source: $reservation,
  target: createReservationFx,
});

sample({
  // @ts-ignore
  clock: createReservationFx.doneData,
  target: reservationCreated,
});

split({
  source: sample({
    clock: createApiErrorStatusStore(createReservationFx),
    filter: Boolean,
  }),
  match: {
    timeSlotIsAlreadyTaken: (status) => status === 422,
  },
  cases: {
    timeSlotIsAlreadyTaken: timeSlotIsAlreadyTakenModalActions.open,
    __: someWrongModalActions.open,
  },
});

export const $isFormSubmitting = pending({
  effects: [createReservationFx],
});
