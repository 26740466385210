import { createEffect } from "effector";

import { apiRequestFx } from "shared/api";

type Reservation = {
  dock: string;
  boat: string;
  timeFrom: string;
  timeTo: string;
};

export const clientCreateReservationFx = createEffect(
  async (reservation: Reservation) => {
    const newReservation = await apiRequestFx({
      method: "POST",
      path: "/api/client/reservations",
      body: reservation,
    });
    return newReservation;
  }
);

type ChangeCharterForReservationParams = {
  reservationId: string;
  isCharter: boolean;
};

export const changeReservationCharterStatusFx = createEffect(
  async (params: ChangeCharterForReservationParams) => {
    const reservation = await apiRequestFx({
      method: "PUT",
      path: `/api/client/reservations/${params.reservationId}/settings`,
      body: {
        isCharter: params.isCharter,
      },
    });

    return reservation;
  }
);

type ChangeAddonsForReservationParams = {
  reservationId: string;
  addons: string[];
};

export const changeReservationAddonsStatusFx = createEffect(
  async (params: ChangeAddonsForReservationParams) => {
    const reservation = await apiRequestFx({
      method: "PUT",
      path: `/api/client/reservations/${params.reservationId}/addons`,
      body: {
        dockAddons: params.addons,
      },
    });
    return reservation;
  }
);

type ChangeReservationBillingInfoParams = {
  reservationId: string;
  billingInfo: {
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
  };
};

export const changeReservationBillingInfoFx = createEffect(
  async (params: ChangeReservationBillingInfoParams) => {
    await apiRequestFx({
      method: "PUT",
      path: `/api/client/reservations/${params.reservationId}/billing`,
      body: params.billingInfo,
    });
  }
);

type GetStripeKeyForPaymentParams = {
  reservationId: string;
  savePaymentMethod: boolean;
};

export const getStripeKeyForPayment = createEffect(
  async (params: GetStripeKeyForPaymentParams) => {
    const { clientSecret } = await apiRequestFx({
      method: "POST",
      path: `/api/client/reservations/${params.reservationId}/pay`,
      body: {
        savePaymentMethod: params.savePaymentMethod,
      },
    });

    return clientSecret;
  }
);

export const clientCanceledReservationFx = createEffect(
  async (reservationId: number) => {
    await apiRequestFx({
      method: "POST",
      path: `/api/client/reservations/${reservationId}/cancel`,
    });
  }
);
