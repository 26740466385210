import { sample } from "effector";
import dayjs from "dayjs";

import { Reservation } from "shared/api/types";
import {
  someWrongModalActions,
  successModalActions,
} from "@client-app/entities/reservation";

import { getDockById } from "entities/docks";

import { createGate } from "effector-react";

import {
  addonsForm,
  changeAddonsStatusFx,
  changeCharterStatusFx,
} from "./addons";
import {
  $dockId,
  $reservation,
  $reservationId,
  paymentFormDomain,
} from "./_base";
import { submitBillingDataFx } from "./billing";
import {
  getStripeKeyFx,
  payWithNewPaymentMethodFx,
  paymentFormSubmitted,
  $savePaymentMethod,
  savePaymentMethodToggle,
} from "./pay-by-card";

import {
  payBySavedMethodClicked,
  payBySavedPaymentMethodFx,
} from "./pay-by-saved-method";

export const gate = createGate();

export const secondsForPayment = 900; // 15 minutes

export const $isTimeUp = paymentFormDomain.createStore(false);
export const $remainingTime = $reservation.map((reservation) => {
  if (reservation) {
    const createdAt = dayjs.tz(reservation.createdAt ?? "");
    return secondsForPayment - dayjs().diff(createdAt, "second");
  }
  return void 0;
});

export const timeUp = paymentFormDomain.createEvent();

$isTimeUp
  .on($remainingTime, (_, remainingTime) =>
    Boolean(remainingTime && remainingTime <= 0)
  )
  .on(timeUp, () => true);

$reservation.on(
  changeCharterStatusFx.doneData,
  (_, reservation) => reservation as Reservation
);
$reservation.on(
  changeAddonsStatusFx.doneData,
  (_, reservation) => reservation as Reservation
);

sample({
  source: $reservation.map((reservation) => reservation?.isCharter),
  target: addonsForm.setValue.prepend((value) => ({
    field: "isCharter",
    value,
  })),
});

sample({
  // @ts-ignore
  source: $reservation.map((reservation) => reservation?.dockAddons),
  target: addonsForm.setValue.prepend((value) => ({
    field: "addons",
    value,
  })),
});

sample({
  // @ts-ignore
  source: $dockId,
  target: getDockById,
});

sample({
  clock: [payWithNewPaymentMethodFx.done, payBySavedPaymentMethodFx.done],
  target: successModalActions.open,
});

sample({
  clock: [
    payWithNewPaymentMethodFx.fail,
    changeCharterStatusFx.fail,
    submitBillingDataFx.fail,
    getStripeKeyFx.fail,
    payBySavedPaymentMethodFx.fail,
  ],
  target: someWrongModalActions.open,
});

export {
  $reservation,
  paymentFormSubmitted,
  payBySavedMethodClicked,
  $savePaymentMethod,
  savePaymentMethodToggle,
};

paymentFormDomain.onCreateStore((store) => store.reset($reservationId));
