import styled from "styled-components";

import { SmallModalTemplate } from "shared/ui/modals";
import { BookingSuccessIcon } from "shared/ui/icons";

export function FeedBackFormSuccessModal(props: {
  isOpened: boolean;
  onClose(): void;
}): JSX.Element {
  return (
    <SmallModalTemplate
      onClose={props.onClose}
      isOpened={props.isOpened}
      renderContent={() => (
        <InfoModalContent>
          <ModalIcon as={BookingSuccessIcon} />
          <Title>Success!</Title>
          <div>
            Thank you, your feedback has been sent and will be processed
          </div>
        </InfoModalContent>
      )}
    />
  );
}

const ModalIcon = styled.div`
  width: 72px;
  height: 72px;
`;

const Title = styled.div`
  font-family: "Hind Siliguri", sans-serif;
  color: #0a1128;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`;

const InfoModalContent = styled.div`
  ${ModalIcon} {
    margin-top: 38px;
    margin-bottom: 28px;
  }
  ${Title} {
    margin-bottom: 12px;
  }
`;
