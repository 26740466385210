import { createStore, createEvent, createEffect, sample } from "effector";
import { reset } from "patronum";

interface Point {
  x: number;
  y: number;
}

interface Crop {
  x: number;
  y: number;
  width: number;
  height: number;
}
export const $selectedFile = createStore<File | null>(null);
export const $selectedImageSrc = createStore<string | null>(null);
export const $zoom = createStore<number>(1);
export const $crop = createStore<Point>({ x: 0, y: 0 });
export const $croppedArea = createStore<Crop | null>(null);

export const fileSelected = createEvent<File>();
export const zoomChanged = createEvent<number>();
export const cropChanged = createEvent<Point>();
export const croppedAreaChanged = createEvent<Crop>();
export const imageDeleted = createEvent();

const readFileFx = createEffect((file: File) => {
  return new Promise<string | null>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
});

$selectedFile.on(fileSelected, (_, file) => file);
$selectedImageSrc.on(readFileFx.doneData, (_, src) => src);
$zoom.on(zoomChanged, (_, zoom) => zoom);
$crop.on(cropChanged, (_, crop) => crop);
$croppedArea.on(croppedAreaChanged, (_, crop) => crop);

sample({
  clock: fileSelected,
  target: readFileFx,
});

reset({
  clock: imageDeleted,
  target: [$selectedFile, $selectedImageSrc, $zoom, $crop, $croppedArea],
});
