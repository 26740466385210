import styled from "styled-components";
import { useGate, useStore } from "effector-react";
import { Tabs as MaterialTabs, Tab } from "@mui/material";
import dayjs from "dayjs";

import { mediaPhone } from "shared/ui/styles/media";
import { Card as CardComponent } from "shared/ui/card";
import { MetaTags } from "shared/lib/metaTags";
import { ConfirmModal, useConfirmModal } from "shared/ui/modals";
import { Reservation } from "shared/api/types";
import { TrashIcon } from "shared/ui/icons";
import { ReservationCard } from "@client-app/entities/reservation";
import { cancelReservation } from "@client-app/features/cancel-reservation";

import { $reservationsList, $filter, filterChanged, pageGate } from "./model";

function isReservationRefundable(reservation: Reservation) {
  return dayjs().isBefore(dayjs(reservation.timeFrom).subtract(72, "hour"));
}

function isReservationPayable(reservation: Reservation) {
  return ["unpaid", "failed", "pending"].includes(reservation.status ?? "");
}

export function ReservationsListPage(): JSX.Element {
  useGate(pageGate);

  const filter = useStore($filter);
  const reservationsList = useStore($reservationsList);

  const [confirmCanceling, confirmModalProps] = useConfirmModal(
    (reservation: Reservation) => {
      cancelReservation(reservation.id);
    },

    (reservation) => ({
      title: "Cancel reservation?",
      Icon: TrashIcon,
      message: (
        <div>
          Are you sure you would like to cancel the <b>reservation</b>?
        </div>
      ),
      secondaryMessage: isReservationPayable(reservation) ? (
        ""
      ) : isReservationRefundable(reservation) ? (
        <div>
          The cost of the reservation will be refunded. This does not apply to $
          {reservation.price!.bookingFee} nonrefundable booking fee
        </div>
      ) : (
        <div>
          The reservation will be canceled and <strong>NOT refunded </strong> as
          it is within 72 hours of the scheduled reservation
        </div>
      ),
      confirmText: "Yes, cancel",
      cancelText: "No",
    })
  );

  return (
    <Root>
      <MetaTags title="Reservations" />
      <Card>
        <Header>List of Reservations</Header>

        <Tabs
          centered
          value={filter}
          onChange={(_, value) => filterChanged(value)}
        >
          <Tab label="Future reservations" value="future" />
          <Tab label="Past reservations" value="past" />
        </Tabs>

        <ReservationList>
          {reservationsList.map((reservation) => (
            <ReservationCard
              key={reservation.id}
              reservation={reservation}
              onCancelClick={() => confirmCanceling(reservation)}
            />
          ))}
        </ReservationList>
      </Card>
      <ConfirmModal {...confirmModalProps} />
    </Root>
  );
}

const Header = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Tabs = styled(MaterialTabs)``;

const Card = styled(CardComponent)`
  ${mediaPhone} {
    padding: 0;
  }
`;

const ReservationList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  ${mediaPhone} {
    grid-template-columns: 1fr;
    gap: 16px;
    justify-content: center;
  }
`;

const Root = styled.div`
  ${Header} {
    margin-bottom: 32px;
  }

  ${Tabs} {
    margin-bottom: 30px;
  }
`;
