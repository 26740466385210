import { Boat } from "shared/api/types";

import { BoatCard } from "entities/boats";
import styled from "styled-components";

export interface Props {
  boat?: Boat | null;
  onClick?(): void;
  className?: string;
}

function NoAuthBoatSelectorComponent(props: Props): JSX.Element {
  return (
    <NoAuthBoatSelectorRoot className={props.className}>
      {props.boat && <BoatCard transparent boat={props.boat} />}
      <SelectButton onClick={props.onClick}>
        {props.boat ? "Select Another Boat" : "Select Boat"}
      </SelectButton>
    </NoAuthBoatSelectorRoot>
  );
}

export const NoAuthBoatSelector = styled(NoAuthBoatSelectorComponent)``;

const SelectButton = styled.div`
  text-decoration: underline;
  color: #034078;
  cursor: pointer;
`;

const NoAuthBoatSelectorRoot = styled.div``;
