import styled from "styled-components";
import { useStore } from "effector-react";
import { Box } from "@mui/material";

import { MetaTags } from "shared/lib/metaTags";
import { Button } from "shared/ui/button";
import {
  createFormField,
  FormError,
  Input,
  SubmitButton,
  TextAreaField,
} from "shared/ui/ecosystems/forms";
import { createMediaMaxWidth, mediaPhone } from "shared/ui/styles/media";
import { PhoneInput } from "shared/ui/ecosystems/forms/atoms/input";
import { Form } from "shared/lib/form";
import { Footer } from "@client-app/shared/ui/footer";
import { Header } from "@client-app/widgets/header";

// @ts-ignore
import videoPlaceholderImg from "./img/video-placeholder.webp";

import {
  $formSubmitted,
  $formSubmitting,
  $serverErrorStatus,
  applyForOperatorForm,
} from "./model";

import mainImage from "./img/main-image.jpeg";
import mainImageMobile from "./img/main-image.mobile.jpeg";
import ourClientsImage from "./img/our-clients.jpg";
import ourClientsBackImage from "./img/our-clients-back.svg";
import line from "./img/line.svg";
import { ReactComponent as Waves1Svg } from "./img/waves1.svg";

export function BecomeAnOperatorPage(): JSX.Element {
  const isSubmitting = useStore($formSubmitting);
  const isSubmitted = useStore($formSubmitted);
  const errorStatus = useStore($serverErrorStatus);

  return (
    <Root>
      <MetaTags title="Become an operator" />
      <Header isHero={true} />
      <HeroBlock>
        <HeroWrapper>
          <HeroContent>
            <Title>Become an Operator</Title>
            <SubTitle>
              Learn more about the benefits of becoming a Downtown Docks
              Operator.
            </SubTitle>

            <HeroButtons>
              <a href="#apply">
                <Button>Become an Operator</Button>
              </a>
            </HeroButtons>
          </HeroContent>

          <VideoPlaceholderImage src={videoPlaceholderImg} alt="Boat" />
          {/* <HeroVideo
            src="https://www.youtube.com/embed/YAKl25G9EPA"
            width="560"
            height="315"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            referrerPolicy="strict-origin-when-cross-origin"
          ></HeroVideo> */}
        </HeroWrapper>
      </HeroBlock>

      <div id="apply">
        <ApplyBlock>
          <BlockTitle>Apply to Become an Operator</BlockTitle>
          <BlockSubTitle>
            Fill out the form below to ask about using Downtown Docks!
          </BlockSubTitle>

          {errorStatus && <FormError>Unknown error</FormError>}
          <Form form={applyForOperatorForm}>
            <FormFields>
              <InputField
                name="firstName"
                type="text"
                label="First name *"
                placeholder="Enter your first name"
              />
              <InputField
                name="lastName"
                type="text"
                label="Last name *"
                placeholder="Enter your last name"
              />
              <PhoneField
                name="phone"
                label="Phone number *"
                placeholder="Enter your phone number"
              />
              <InputField
                name="email"
                type="email"
                label="Email address *"
                placeholder="Enter your email address"
              />
              <InputField
                name="company"
                type="text"
                label="Company *"
                placeholder="Enter your company"
              />
              <TextAreaField
                name="inquiry"
                label="Inquiry"
                minRows={3}
                maxRows={3}
                className="grid-span-2"
              />
            </FormFields>

            <Actions>
              <SubmitButton isSubmitting={isSubmitting}>Submit</SubmitButton>
              {isSubmitted ? (
                <SubmittedMessage>
                  The form was successfully submitted
                </SubmittedMessage>
              ) : null}
            </Actions>
          </Form>
        </ApplyBlock>
      </div>

      <OurClientsBlock>
        <Waves1 />
        <BlockTitle>Hear from One of Our Clients</BlockTitle>

        <OurClientsContent>
          <OurClientsMessage>
            <p>
              "Running a dock in Chicago has been straightforward with Downtown
              Docks. The system is user-friendly and helps keep everything
              organized, even during busier times. The web app is easy to
              navigate, which we appreciate. Downtown Docks has made managing
              our operations more efficient."
            </p>
            <p className="font-bold">- JBYS Team</p>
          </OurClientsMessage>

          <OurClientsImage>
            <OurClientsBackImg />
            <OurClientsImg src={ourClientsImage} />
          </OurClientsImage>
        </OurClientsContent>
      </OurClientsBlock>

      <Footer />
    </Root>
  );
}

const Content = styled.div`
  position: relative;

  ${createMediaMaxWidth(1200)} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Title = styled.h1`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 54px;
  line-height: 66px;

  width: 100%;
  max-width: 587px;
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  width: 480px;

  ${mediaPhone} {
    width: auto;
    max-width: 508px;
  }
`;

const HeroContent = styled(Content)`
  margin-bottom: 65px;

  ${Title} {
    margin-bottom: 48px;
  }

  ${SubTitle} {
    margin-bottom: 48px;
  }

  ${createMediaMaxWidth(1200)} {
    margin-top: 60px;
    margin-bottom: 0;
  }
`;

const HeroBlock = styled.div`
  background: url(${mainImage}) no-repeat;
  background-size: cover;
  background-position: bottom right;
  min-height: 768px;
  display: flex;
  align-items: center;

  color: #fbfcff;

  ${Button} {
    background-color: #3bb7b6;
    width: 180px;
  }

  a {
    text-decoration: none;
  }

  ${createMediaMaxWidth(768)} {
    background-image: url(${mainImageMobile});
  }
`;

const HeroWrapper = styled.div`
  max-width: calc(var(--contentWidth) + 40px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;

  ${createMediaMaxWidth(1200)} {
    flex-direction: column;
  }
`;

const HeroButtons = styled.div`
  display: flex;
  gap: 20px;
`;

const BlockTitle = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #0a1128;
  display: inline-block;

  :after {
    content: "";
    display: block;
    background: url(${line}) no-repeat;
    background-size: 100% 100%;
    height: 16px;
    width: 100%;
  }
`;

const BlockSubTitle = styled.p`
  font-size: 20px;
  line-height: 1.5;
  margin-top: 30px;
`;

const Block = styled((props) => (
  <BlockRoot className={props.className}>
    <Content>{props.children}</Content>
  </BlockRoot>
))`
  text-align: center;
  padding-top: 64px;

  ${mediaPhone} {
    text-align: left;
  }
`;

const BlockRoot = styled.div``;

const Waves1 = styled(Waves1Svg)`
  width: 130px;
  height: 52px;
`;

const OurClientsMessage = styled.div`
  max-width: 500px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0a1128;
  text-align: left;
  margin-top: 40px;

  ${createMediaMaxWidth(1200)} {
    padding: 0;
  }

  p.font-bold {
    font-weight: bold;
  }
`;

const OurClientsImg = styled.img`
  max-width: 520px;
  ${createMediaMaxWidth(1200)} {
    max-width: 100%;
  }
  border-radius: 24px;
`;

const OurClientsBackImg = styled.div`
  width: 520px;
  height: 338px;

  background: url(${ourClientsBackImage});
`;

const OurClientsImage = styled.div`
  position: relative;

  ${createMediaMaxWidth(1200)} {
    max-width: calc(100vw - 32px);
    text-align: center;
  }

  ${OurClientsBackImg} {
    position: absolute;
    top: 0;
    left: -40px;

    ${createMediaMaxWidth(768)} {
      top: -80px;
      left: 0;
    }
  }

  ${OurClientsImg} {
    position: relative;
    z-index: 1;
  }
`;

const OurClientsContent = styled.div`
  width: 100%;
  margin-top: 100px;

  display: flex;
  justify-content: space-between;
  gap: 20px;

  ${createMediaMaxWidth(1200)} {
    align-items: center;
    flex-direction: column-reverse;
    gap: 24px;
  }
`;

const OurClientsBlock = styled(Block)`
  max-width: var(--contentWidth);
  margin: 0 auto;
  padding-bottom: 120px;

  ${createMediaMaxWidth(1200)} {
    overflow: hidden;
  }

  ${Waves1} {
    position: absolute;
    top: 64px;
    left: 80px;

    ${mediaPhone} {
      top: -31px;
      right: 30px;
    }
  }

  ${createMediaMaxWidth(1000)} {
    ${BlockTitle} {
      margin-top: 40px;
      text-align: center;
      width: 100%;
    }
  }
`;

const ViewMoreButton = styled(Button)`
  width: 200px !important;
`;

const Docks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  ${createMediaMaxWidth(1200)} {
    grid-template-columns: 1fr;
  }
`;

const ApplyBlock = styled(Block)`
  max-width: var(--contentWidth);
  margin: 48px auto;
  padding: 48px;
  background: #f1f3fa;
  border-radius: 16px;

  ${createMediaMaxWidth(768)} {
    margin: 0;
  }
`;

const Root = styled.div`
  --contentWidth: 1180px;

  ${mediaPhone} {
    --contentWidth: 100vw;
  }

  ${Header} {
    position: absolute;
    top: 0;
    z-index: 1;
  }

  ${Docks} {
    margin-top: 40px;
  }

  ${ViewMoreButton} {
    display: block;
    margin: 30px auto 0;
  }
`;

const HeroVideo = styled.iframe`
  max-width: 100%;
  border-radius: 5px;
  border: 0;

  ${createMediaMaxWidth(1200)} {
    margin-top: 48px;
    margin-bottom: 48px;
  }
`;

export const InputField = createFormField(Input);

export const PhoneField = createFormField(PhoneInput);

export const FormFields = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 60px;
  padding: 40px 100px;

  .grid-span-2 {
    grid-column: 1 / span 2;
  }

  ${createMediaMaxWidth(1200)} {
    gap: 15px 30px;
    padding: 20px 50px;
  }

  ${createMediaMaxWidth(1000)} {
    grid-template-columns: 1fr;
    gap: 10px 20px;
    padding: 0;

    .grid-span-2 {
      grid-column: auto;
    }
  }
`;

const Actions = styled.div`
  margin-top: 40px;

  button {
    min-width: 180px;
  }
`;

const SubmittedMessage = styled.p`
  margin-top: 24px;
  color: #16a34a;
`;

const VideoPlaceholderImage = styled.img`
  width: 100%;
  border-radius: 16px;
  max-width: 560px;
  max-height: 315px;

  ${createMediaMaxWidth(1200)} {
    padding: 16px 16px 0 16px;
  }
`;
