import { attach, createEvent, createStore, sample } from "effector";
import { createGate } from "effector-react";

import { spreadListDataData } from "shared/lib/effector-paginatoin";
import { getListFromApi, getBoatCollection } from "shared/api";
import { Boat } from "shared/api/types";

export const Gate = createGate();
export const $clientsBoats = createStore<Boat[]>([]);
export const $clientMainBoat = $clientsBoats.map(
  (boats) => boats.find((boat) => boat.isMain) ?? null
);

export const boatAdded = createEvent<Boat>();
export const boatDeleted = createEvent<number>();

export const getClientsBoatsFx = attach({
  // @ts-ignore
  effect: getListFromApi(getBoatCollection),
  mapParams: () => ({}),
});

$clientsBoats
  .on(boatAdded, (boats, boat) => [boat, ...boats])
  .on(boatDeleted, (boats, boatId) =>
    boats.filter((boat) => boat.id !== boatId)
  );

spreadListDataData({
  $items: $clientsBoats,
  effect: getClientsBoatsFx,
});

sample({
  clock: Gate.open,
  target: getClientsBoatsFx,
});
