import styled from "styled-components";

import { MetaTags } from "shared/lib/metaTags";
import { createMediaMaxWidth, mediaPhone } from "shared/ui/styles/media";
import { EditProfileForm } from "@client-app/features/edit-profile";
import { useGate, useUnit } from "effector-react";

import { PaymentMethod } from "shared/ui/payment-method";
import { Button } from "shared/ui/button";
import { ConfirmModal } from "shared/ui/modals";

import { deletePaymentMethodClicked, pageGate, confirmModal } from "./model";
import { $paymentsMethods } from "../../entities/payments-methods";

export function ProfileEditPage(): JSX.Element {
  useGate(pageGate);
  const [paymentsMethods] = useUnit([$paymentsMethods]);

  const confirm = useUnit(confirmModal);

  return (
    <Root>
      <MetaTags title="Profile" />
      <Header>Profile</Header>
      <Content>
        <ProfileCard>
          <CardTitle>Personal information</CardTitle>
          <EditProfileForm />
        </ProfileCard>
        {paymentsMethods.length > 0 && (
          <ProfileCard>
            <CardTitle>Payment options</CardTitle>
            <Message>
              A new payment method may only be added during checkout
            </Message>

            <PaymenstList>
              {paymentsMethods.map((method) => (
                <PaymentMethod
                  key={method.id}
                  method={method}
                  renderActions={() => (
                    <Button
                      onClick={() => deletePaymentMethodClicked(method.id)}
                      color={"danger"}
                    >
                      Delete
                    </Button>
                  )}
                />
              ))}
            </PaymenstList>
          </ProfileCard>
        )}
      </Content>

      <ConfirmModal
        title={"Delete Payment Method?"}
        message="Are you sure you want to delete this method?"
        {...confirm}
      />
    </Root>
  );
}

const Header = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const CardTitle = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

const Message = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5e616c;
  margin-bottom: 24px;
`;

const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #f1f3fa;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0a1128;

  ${CardTitle} {
    margin-bottom: 16px;
  }
`;

const PaymenstList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 380px;
  grid-gap: 20px;

  ${createMediaMaxWidth(1200)} {
    grid-template-columns: 1fr;
  }
`;

const Root = styled.div`
  ${Header} {
    margin-bottom: 32px;
  }
`;
