import styled from "styled-components";
import { useStore } from "effector-react";

import {
  FormAuthTemplate,
  FormFields,
} from "shared/ui/templates/auth-form-template";
import { SubmitButton, InputField } from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";

import {
  $isFormSubmitting,
  $serverErrorStatus,
  forgotPasswordForm,
} from "../models/forgot-password-form-model";

const errorsByStatus = {
  400: "User with this email does not exist.",
};

export function ForgotPasswordForm(): JSX.Element {
  const isSubmitting = useStore($isFormSubmitting);
  const errorStatus = useStore($serverErrorStatus);

  return (
    <Form form={forgotPasswordForm}>
      <FormAuthTemplate
        error={errorStatus && (errorsByStatus[errorStatus] ?? "Unknown error")}
        header="Forgot password?"
        subHeader="Don't worry! Just fill in your email and we'll send you a link to reset your password."
        renderContent={() => (
          <FormFields>
            <InputField
              name="email"
              type="email"
              label="Email"
              placeholder="Enter email"
            />
          </FormFields>
        )}
        renderAction={() => (
          <Actions>
            <SubmitButton isSubmitting={isSubmitting}>
              Reset password
            </SubmitButton>
          </Actions>
        )}
      />
    </Form>
  );
}

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;
