import { configure } from "features/password-recovery";
import {
  postClientForgotPassword,
  postClientForgotPasswordToken,
} from "shared/api";

import { init as initResrvationProcess } from "@client-app/processes/client-reservation";

export function init(): void {
  initResrvationProcess();

  configure({
    forgotPasswordFx: postClientForgotPassword,
    createNewPasswordFx: postClientForgotPasswordToken,
  });
}
