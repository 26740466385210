import { InputBaseComponentProps, TextField, useTheme } from "@mui/material";
import styled from "styled-components";
import {
  forwardRef,
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js/types/stripe-js/elements";

import { stripePromise } from "shared/config/stripe";

interface PaymentFormProps {
  children: ReactNode;
  onSubmit(stripeCardNumberElement: StripeCardNumberElement): void;
}

export function PaymentForm(props: PaymentFormProps): JSX.Element {
  return (
    <Elements stripe={stripePromise}>
      <PaymentFormContent onSubmit={props.onSubmit}>
        {props.children}
      </PaymentFormContent>
    </Elements>
  );
}

function PaymentFormContent(props: PaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();

  function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();

    e.preventDefault();
    if (!elements || !stripe) {
      return;
    }
    // @ts-ignore
    props.onSubmit(elements.getElement(CardNumberElement));
  }
  return <form onSubmit={handleSubmit}>{props.children}</form>;
}

export function PaymentFormFields(props: {
  onComplete(): void;
  onInComplete(): void;
}): JSX.Element {
  const [cardCompeted, setCardCompeted] = useState(false);
  const [expiryCompeted, setExpiryCompeted] = useState(false);
  const [CVCCompeted, setCvcCompeted] = useState(false);

  const formCompleted = cardCompeted && expiryCompeted && CVCCompeted;

  useEffect(() => {
    if (formCompleted) {
      props.onComplete();
    } else {
      props.onInComplete();
    }
  }, [formCompleted]);

  return (
    <PaymentFormFieldsRoot>
      <CardNumberInput onCompletedChanged={setCardCompeted} />
      <PaymentFormFieldsLine>
        <CardExpiryInput onCompletedChanged={setExpiryCompeted} />
        <CardCvcInput onCompletedChanged={setCvcCompeted} />
      </PaymentFormFieldsLine>
    </PaymentFormFieldsRoot>
  );
}

const PaymentFormFieldsRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
`;
const PaymentFormFieldsLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`;

export function CardNumberInput(props: {
  onCompletedChanged(value: boolean): void;
}): JSX.Element {
  return (
    <StripeInput
      label="Enter card number *"
      element={CardNumberElement}
      onCompletedChanged={props.onCompletedChanged}
    />
  );
}
export function CardExpiryInput(props: {
  onCompletedChanged(value: boolean): void;
}): JSX.Element {
  return (
    <StripeInput
      label="Enter validity *"
      element={CardExpiryElement}
      onCompletedChanged={props.onCompletedChanged}
    />
  );
}
export function CardCvcInput(props: {
  onCompletedChanged(value: boolean): void;
}): JSX.Element {
  return (
    <StripeInput
      label="Enter CVC *"
      element={CardCvcElement}
      onCompletedChanged={props.onCompletedChanged}
    />
  );
}

function StripeInput(props: {
  element: FunctionComponent;
  label: string;
  onCompletedChanged(value: boolean): void;
}) {
  return (
    <TextField
      /* @ts-ignore */
      onChange={(data) => props.onCompletedChanged(data.complete)}
      label={props.label}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputProps: {
          component: props.element,
        },
        inputComponent: StripeBaseInput,
      }}
    />
  );
}

const StripeBaseInput = forwardRef<any, InputBaseComponentProps>(
  function StripeInput(props, ref) {
    const { component: Component, options, ...other } = props;
    const theme = useTheme();
    const [mountNode, setMountNode] = useState<any | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => mountNode.focus(),
      }),
      [mountNode]
    );

    return (
      <Component
        onReady={setMountNode}
        options={{
          ...options,
          style: {
            base: {
              color: theme.palette.text.primary,
              fontSize: "16px",
              lineHeight: "1.4375em", // 23px
              fontFamily: "Hind Siliguri,sans-serif",
              "::placeholder": {
                color: "#969899",
              },
            },
            invalid: {
              color: theme.palette.text.primary,
            },
          },
        }}
        {...other}
      />
    );
  }
);
