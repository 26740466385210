import { useEffect } from "react";
import styled from "styled-components";
import { useStore } from "effector-react";
import {
  DateInput,
  FormModalTemplate,
  Select,
} from "shared/ui/ecosystems/forms";
import { Button as ButtonComponent } from "shared/ui/button";
import { Header, SubHeader } from "shared/ui/templates/form-template";
import { mediaDesktop } from "shared/ui/styles/media";
import { CitySelector } from "@client-app/entities/cities";
import {
  $noAuthUserBoat,
  BoatSelector,
  NoAuthBoatSelector,
} from "@client-app/entities/boats";
import { AddBoat } from "@client-app/features/manage-boats/add";
import { $viewer } from "@client-app/entities/viewer";

import { createQueryParamStore } from "shared/lib/effector-router-params";

import {
  $selectedBoatURI,
  selectedBoatIdChanged,
  $duration,
  durationChanged,
  $date,
  dateChanged,
  $availableDaysItems,
  selectedMonthChanged,
  $selectedCityURI,
  selectedCityIdChanged,
} from "./model";
import { convertDateToApi } from "../../../shared/lib/dayjs-ext";

const $cityIdQuery = createQueryParamStore({ name: "cityId" });

interface Props {
  isModal: boolean;
  className?: string;
  isOpened: boolean;
  onClose(): void;
}
function FilterComponent(props: Props): JSX.Element {
  const header = "Filter";
  const subHeader =
    "Choose a boat from our database or add your own to show available docks";

  return props.isModal ? (
    <FormModalTemplate
      isOpened={props.isOpened}
      onClose={props.onClose}
      renderTitle={() => header}
      renderSubtitle={() => <>{subHeader}</>}
      renderContent={() => <FilterContent />}
      renderActions={() => <Button onClick={props.onClose}>Filter</Button>}
    />
  ) : (
    <RootFilter className={props.className}>
      <Header>{header}</Header>
      <SubHeader>{subHeader}</SubHeader>
      <FilterContent />
    </RootFilter>
  );
}

function FilterContent(): JSX.Element {
  const viewer = useStore($viewer);
  const cityIdQuery = useStore($cityIdQuery);
  const cityId = useStore($selectedCityURI);
  const boatId = useStore($selectedBoatURI);
  const noAuthUserBoat = useStore($noAuthUserBoat);

  useEffect(() => {
    if (cityIdQuery) {
      selectedCityIdChanged(`/api/cities/${cityIdQuery}`);
    }
  }, [cityIdQuery]);

  return (
    <RootContent>
      <CitySelector
        value={cityId}
        label="City"
        onChange={selectedCityIdChanged}
      />

      {viewer ? (
        <AddBoat>
          {({ openAddBoatModal }) => (
            <BoatSelector
              value={boatId}
              label="Saved boats"
              onChange={selectedBoatIdChanged}
              onAddBoatClick={openAddBoatModal}
            />
          )}
        </AddBoat>
      ) : (
        <AddBoat>
          {({ openAddBoatModal }) => (
            <NoAuthBoatSelector
              boat={noAuthUserBoat as any}
              onClick={openAddBoatModal}
            />
          )}
        </AddBoat>
      )}

      <Time>
        <Label>Time</Label>
        <DurationSelector />
        <DateSelector />
      </Time>
    </RootContent>
  );
}

const durationsOptions = new Array(23)
  .fill(0)
  .map((_, index) => index + 1)
  .map((duration) => ({
    value: duration,
    label: `${duration} hr`,
  }));

function DurationSelector() {
  const viewer = useStore($viewer);
  const noAuthUserBoat = useStore($noAuthUserBoat);
  const duration = useStore($duration);

  return (
    <Select
      label="Duration"
      value={duration}
      onChange={durationChanged}
      disabled={!noAuthUserBoat && !viewer}
      options={durationsOptions}
    />
  );
}

function DateSelector() {
  const date = useStore($date);
  const availableDaysItems = useStore($availableDaysItems);

  return (
    <DateInput
      label="Date"
      value={date as any}
      onChange={dateChanged as any}
      onMonthChange={selectedMonthChanged}
      shouldDisableDate={(date) => {
        return !availableDaysItems[convertDateToApi(date)];
      }}
    />
  );
}

const Label = styled.div`
  color: #5e616c;
  font-family: "Hind Siliguri", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Time = styled.div`
  display: flex;
  flex-direction: column;
`;

const DockAccessibility = styled.div``;

const Button = styled(ButtonComponent)`
  width: 100%;
`;

const RootContent = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;

  border-radius: 24px;

  ${mediaDesktop} {
    background: #f7f9fc;
  }

  ${Time} {
    margin-bottom: 16px;
  }
`;
const RootFilter = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  background: #f7f9fc;
  border-radius: 24px;
  padding: 32px 24px 24px;
  ${Header} {
    margin-bottom: 12px;
  }
  ${SubHeader} {
    margin-bottom: 32px;
  }
  ${NoAuthBoatSelector} {
    margin-bottom: 16px;
  }
  ${Time} {
    margin-top: 16px;
  }
  ${DockAccessibility} {
    margin-bottom: 32px;
  }
`;
export const Filter = styled(FilterComponent)``;
