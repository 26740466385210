import { createForm } from "effector-react-form";
import { attach, createEvent, createStore, sample } from "effector";

import { debounce, or } from "patronum";

import { formField } from "../../../../shared/lib/form";
import { $reservationId, paymentFormDomain } from "./_base";
import { getDockById } from "../../../../entities/docks";
import {
  changeReservationAddonsStatusFx,
  changeReservationCharterStatusFx,
} from "../../../entities/reservation";

export const addonsForm = createForm({
  domain: paymentFormDomain,
  initialValues: {
    isCharter: false,
    addons: [],
  },
  onSubmit: () => addonsSubmitted(),
});
export const $dockAddons = createStore([]);
export const $addons = formField(addonsForm, "addons");
export const $isCharter = formField(addonsForm, "isCharter");
export const $addonsSubmitted = paymentFormDomain.createStore(false);

export const addonClicked = createEvent<string>();
export const addonsChanged = createEvent();

export const addonsSubmitted = paymentFormDomain.createEvent();

export const changeCharterStatusFx = attach({
  effect: changeReservationCharterStatusFx,
  source: $reservationId,
  mapParams(isCharter: boolean, reservationId) {
    return {
      reservationId,
      isCharter,
    };
  },
});
export const changeAddonsStatusFx = attach({
  effect: changeReservationAddonsStatusFx,
  source: $reservationId,
  mapParams(addons: string[], reservationId: string) {
    return {
      reservationId,
      addons,
    };
  },
});

$dockAddons.on(getDockById.doneData, (_, dock) => dock.addons);
$addonsSubmitted.on(addonsSubmitted, () => true);

sample({
  clock: addonClicked,
  source: $addons,
  fn: (addons, addon) =>
    addons.includes(addon)
      ? addons.filter((value) => value !== addon)
      : [...addons, addon],
  target: addonsChanged,
});

sample({
  source: $isCharter,
  target: changeCharterStatusFx,
});

sample({
  clock: debounce({ source: addonsChanged, timeout: 500 }),
  target: changeAddonsStatusFx,
});

export const $isAddonsSaving = or(
  changeCharterStatusFx.pending,
  changeAddonsStatusFx.pending
);
