import { createForm } from "effector-react-form";
import { object, string, boolean } from "yup";
import { createEffect } from "effector";
import { createGate } from "effector-react";

import { getUserDataFx } from "@client-app/entities/viewer";
import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore, postClientRegister } from "shared/api";
import { phoneValidationError } from "shared/config/error-text";

interface userSignUpdata {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
}

const clientSignUpFx = createEffect(async (userData: userSignUpdata) => {
  // @ts-ignore
  await postClientRegister({ body: userData });
  await getUserDataFx({});
});
export const signUpFormGate = createGate();
export const signUpForm = createForm({
  name: "Sign UP Form",
  initialValues: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    agreeWithRules: false,
  },
  validate: createValidator(
    object({
      firstName: string().required(" "),
      lastName: string().required(" "),
      phone: string().phone("US", phoneValidationError),
      email: string().email(" ").required(" "),
      password: string().required(" "),
      agreeWithRules: boolean()
        .required()
        .oneOf([true], "The terms and conditions must be accepted."),
    })
  ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSubmit: ({ values: { agreeWithRules, ...userData } }) =>
    clientSignUpFx(userData),
});

export const $isFormSubmitting = clientSignUpFx.pending;
export const $serverErrorStatus = createApiErrorStatusStore(clientSignUpFx);
$serverErrorStatus.reset(signUpFormGate.close);
