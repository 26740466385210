import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { useStore } from "effector-react";
import { ButtonBase, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

import { DowntownLogo } from "shared/ui/downtown-logo";
import { mediaPhone } from "shared/ui/styles/media";
import { useOpenableState } from "shared/lib/use-openable-state";
import { Avatar } from "shared/ui/avatar/avatar";
import {
  NavigationMenuIcon,
  NavigationMenuItem,
} from "shared/ui/templates/authorized-page-template";
import { ReactComponent as LogoutIcon } from "shared/ui/templates/authorized-page-template/logout.svg";
import { $viewer } from "@client-app/entities/viewer";
import { NavigationContext } from "shared/ui/templates/authorized-page-template/navigation-menu";
import { ReactComponent as MenuIconSVG } from "shared/ui/templates/authorized-page-template/menu.svg";
import { ModalLinkButton } from "shared/ui/button";

export interface Props {
  navigation: ReactNode;
  onSignOutClick(): void;
  className?: string;
  isHero?: boolean;
}

export function MobileHeader(props: Props): JSX.Element {
  const user = useStore($viewer);
  const sideBar = useOpenableState();
  const userName = user ? [user.firstName, user.lastName].join(" ") : "";

  return (
    <NavigationContext.Provider value={{ onItemClick: sideBar.close }}>
      <Root className={props.className} $isHero={props.isHero}>
        <Header $isHero={props.isHero}>
          <HomePageLink to="/">
            <DowntownLogo $dark={!props.isHero} />
          </HomePageLink>

          {user ? (
            <>
              <UserButton onClick={sideBar.open} name={userName} />
              <Drawer
                anchor="right"
                open={sideBar.isOpened}
                onClose={sideBar.close}
              >
                <SideBar>
                  <SideBarHeader>
                    <CloseButton onClose={sideBar.close}></CloseButton>
                  </SideBarHeader>

                  <UserInfo>
                    <Avatar name={userName} />
                    <div>
                      <UserName>{userName}</UserName>
                      <UserEmail>{user.email}</UserEmail>
                    </div>
                  </UserInfo>

                  <Navigation>{props.navigation}</Navigation>

                  <LogoutBlock>
                    <NavigationMenuItem onClick={props.onSignOutClick}>
                      <NavigationMenuIcon as={LogoutIcon} /> Logout
                    </NavigationMenuItem>
                  </LogoutBlock>
                </SideBar>
              </Drawer>
            </>
          ) : (
            <>
              <SignInButton modal="login" variant="outlined">
                Sign In / Sign Up
              </SignInButton>
            </>
          )}
        </Header>
      </Root>
    </NavigationContext.Provider>
  );
}

const SignInButton = styled(ModalLinkButton)``;

const Header = styled.div`
  z-index: 1;

  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${DowntownLogo} {
    width: 160px;
    height: 50px;
  }
`;

const CloseButton = styled((props: { className?: string; onClose(): void }) => {
  return (
    <IconButton
      className={props.className}
      aria-label="close"
      onClick={props.onClose}
      sx={{
        size: 18,
        color: (theme) => theme.palette.primary.main,
      }}
    >
      <CloseIcon />
    </IconButton>
  );
})``;

const HomePageLink = styled(Link)`
  display: flex;
`;

const SideBarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;

  ${CloseButton} {
    justify-self: end;
  }
`;

const UserName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #0a1128;
`;

const UserEmail = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5e616c;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;

  padding: 0 16px;

  ${Avatar} {
    width: 64px;
    height: 64px;

    font-size: 26px;

    margin-right: 16px;
  }
`;

const Navigation = styled.div``;

const LogoutBlock = styled.div`
  display: grid;
  border-top: 1px solid #e7ebf7;
  padding-top: 16px;

  ${mediaPhone} {
    margin-top: 40px;
  }
`;

const SideBar = styled.div`
  width: 100vw;
  padding-top: 24px;

  ${SideBarHeader} {
    margin-bottom: 32px;
  }

  ${UserInfo} {
    margin-bottom: 42px;
  }
`;

const Root = styled.div`
  width: 100vw;
  background: #fbfcff;
  border-bottom: 1px solid #e7ebf7;

  ${(props) =>
    props.$isHero &&
    css`
      background: transparent;
      border: none;

      ${SignInButton} {
        color: #fbfcff;
        border-color: #fbfcff;
      }
    `}
`;

function UserButton(props: { onClick(): void; name: string }) {
  return (
    <ButtonBase>
      <UserButtonRoot onClick={props.onClick}>
        <Avatar name={props.name} />
        <MenuIcon />
      </UserButtonRoot>
    </ButtonBase>
  );
}

const MenuIcon = styled(MenuIconSVG)`
  width: 24px;
  height: 24px;
`;

const UserButtonRoot = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 4px 15px 4px 4px;

  background: #f5fafa;
  border: 1px solid #cfe2f3;
  box-sizing: border-box;
  border-radius: 600px;

  color: #2d4fa1;

  ${Avatar} {
    width: 32px;
    height: 32px;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: #fbfcff;
  }
`;
