import styled from "styled-components";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { BoatIcon as BoatIconSvg, LocationIcon } from "shared/ui/icons";
import { mediaPhone } from "shared/ui/styles/media";
import { Reservation } from "shared/api/types";
import { removeTimeZone } from "shared/lib/dayjs-ext";
import { Button, LinkButton } from "shared/ui/button";
import { isFutureValidation } from "entities/reservations";
import { getDockShortAddress } from "entities/docks/lib/adress";
import { stringToFixed } from "shared/lib/string-to-fixed";

import { PaidTag } from "./paid-tag";

interface Props {
  className?: string;
  reservation: Reservation;
  nonInteractive?: boolean;
  hidePrice?: boolean;
  onCancelClick?(): void;
}

function isReservationCancelable(reservation: Reservation) {
  return isFutureValidation(reservation) && reservation.status !== "cancelled";
}

export function ReservationCard(props: Props): JSX.Element {
  const isPayable = ["failed", "pending", "unpaid"].includes(
    props.reservation.status ?? ""
  );

  const isNotExpired = isReservationNotExpired(props.reservation);
  const isFuture = isFutureValidation(props.reservation);

  const canBePaid = isPayable && isFuture && isNotExpired;

  return (
    <DockCardRoot className={props.className}>
      <Header>
        <Name to={`/dock-list/${props.reservation.dock.id}`}>
          {props.reservation.dock.name}
        </Name>
        {!props.nonInteractive && <PaidTag reservation={props.reservation} />}
      </Header>
      <LocationLine>
        <Icon as={LocationIcon} />
        <Location>{getDockShortAddress(props.reservation.dock)}</Location>
      </LocationLine>
      <DockImage src={props.reservation.dock.image.url ?? ""} alt="" />
      <Dates>
        <Date>{formatDate(props.reservation.timeFrom)}</Date>
        <Date>{formatDate(props.reservation.timeTo)}</Date>
      </Dates>

      <Boat>
        <BoatIcon />
        {props.reservation.boat?.name}
      </Boat>

      {!props.hidePrice && (
        <Footer>
          <Hr />
          <ReservationPrice reservation={props.reservation} />
          {!props.nonInteractive && canBePaid && (
            <PayButton to={`/reservations-list/${props.reservation.id}/pay`}>
              Pay
            </PayButton>
          )}
          {isReservationCancelable(props.reservation) && props.onCancelClick && (
            <Actions>
              <CancelButton color="danger" onClick={props.onCancelClick}>
                Cancel
              </CancelButton>
            </Actions>
          )}
        </Footer>
      )}
    </DockCardRoot>
  );
}

function isReservationNotExpired(reservation: Reservation): boolean {
  return dayjs().diff(dayjs.tz(reservation.createdAt), "minute") < 15;
}

function formatDate(date: string) {
  return dayjs(removeTimeZone(date)).format("ddd DD MMM YYYY hh:mm A");
}

const BoatIcon = styled(BoatIconSvg)`
  width: 24px;
  height: 24px;
  color: #b0e9e9;
`;

const Boat = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #2d4fa2;
`;

const Date = styled.div`
  padding: 12px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  :first-child {
    border-right: 1px solid #e7ebf7;
  }
`;

const Dates = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 70px;
  background-color: #f5fafa;
  border-radius: 16px;
`;

const Hr = styled.div`
  height: 0;
  margin-left: -24px;
  margin-right: -24px;
  border-top: 1px solid #bdd6eb;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

const Name = styled(Link)`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #0a1128;
  text-decoration: none;
  ${mediaPhone} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Footer = styled.div``;

const Location = styled.div`
  color: #5e616c;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
`;

const LocationLine = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const PayButton = styled(LinkButton)`
  width: 100%;
`;

const CancelButton = styled(Button)`
  color: #de2016; ;
`;

const Actions = styled.div`
  display: flex;
  padding: 24px 24px 0;
  margin-left: -24px;
  margin-right: -24px;
  border-top: 1px solid #bdd6eb;
  ${CancelButton} {
    width: 100%;
  }
`;

const DockImage = styled.img`
  width: 100%;
  border-radius: 24px;
`;

const DockCardRoot = styled.div`
  padding: 24px;
  background: #f1f3fa;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5e616c;

  ${Header} {
    margin-bottom: 10px;
  }

  ${PaidTag} {
    margin-right: -24px;
  }

  ${LocationLine} {
    margin-bottom: 12px;
  }

  ${DockImage} {
    margin-bottom: 16px;
  }

  ${Dates} {
    margin-bottom: 19px;
  }

  ${Boat} {
    margin-bottom: 19px;
  }
  ${Hr} {
    margin-bottom: 20px;
  }

  ${PayButton} {
    margin-top: 27px;
  }
  ${Actions} {
    margin-top: 24px;
  }
`;

export function ReservationPrice(props: {
  reservation: Reservation;
}): JSX.Element {
  const beforeTaxAddons = props.reservation.price?.addons.filter(
    (addon) => addon.addonTax !== null
  );
  const afterTaxAddons = props.reservation.price?.addons.filter(
    (addon) => addon.addonTax === null
  );

  return (
    <PriceRoot>
      <PriceBlock>
        <PriceLine>
          <PriceLabel>Price:</PriceLabel>
          <Price>${stringToFixed(props.reservation.price.dockPrice)}</Price>
        </PriceLine>
        <PriceLine>
          <PriceLabel>
            Tax
            <PriceDetail>
              (Mooring Tax {stringToFixed(props.reservation.price.taxRate)}%):
            </PriceDetail>
          </PriceLabel>
          <Price>${stringToFixed(props.reservation.price.tax)}</Price>
        </PriceLine>
      </PriceBlock>
      {beforeTaxAddons?.length > 0 &&
        beforeTaxAddons.map((addon) => (
          <PriceBlock>
            <PriceLine>
              <PriceLabel>{addon.addonName}</PriceLabel>
              <Price>${stringToFixed(addon.price)}</Price>
            </PriceLine>
            <PriceLine>
              <PriceLabel>
                Tax
                {addon.addonTaxType === "percent" && (
                  <PriceDetail>
                    (addon tax {stringToFixed(addon.addonTax)}%)
                  </PriceDetail>
                )}
                :
              </PriceLabel>
              <Price>${stringToFixed(addon.tax)}</Price>
            </PriceLine>
          </PriceBlock>
        ))}

      {afterTaxAddons?.length > 0 &&
        afterTaxAddons.map((addon) => (
          <PriceLine>
            <PriceLabel>{addon.addonName}</PriceLabel>
            <Price>${stringToFixed(addon.price)}</Price>
          </PriceLine>
        ))}

      <PriceLine>
        <PriceLabel>Booking Fee:</PriceLabel>
        <Price>${stringToFixed(props.reservation.price.bookingFee)}</Price>
      </PriceLine>
      {props.reservation.price.charterFee && (
        <PriceLine>
          <PriceLabel>Charter Fee:</PriceLabel>
          <Price>${stringToFixed(props.reservation.price.charterFee)}</Price>
        </PriceLine>
      )}

      <TotalPriceLine>
        <TotalPriceLabel>Total price:</TotalPriceLabel>
        <TotalPrice>
          ${stringToFixed(props.reservation.price.totalPrice)}
        </TotalPrice>
      </TotalPriceLine>
    </PriceRoot>
  );
}

const PriceLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #5e616c;
`;

const PriceDetail = styled(PriceLabel)`
  color: #3bb7b6;
`;

const Price = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #0a1128;
`;

const PriceLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > * {
    &:not(:first-child) {
      margin-left: 6px;
    }
  }
`;

const TotalPriceLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0a1128;
`;

const TotalPrice = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #5e88ef;
`;

const TotalPriceLine = styled(PriceLine)`
  margin-top: 11px;
`;

const PriceRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
