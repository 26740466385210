import { createForm } from "effector-react-form";
import { attach, createEffect, createStore, sample } from "effector";
import { createGate } from "effector-react";
import { object, string } from "yup";

import { createValidator } from "shared/lib/form";
import { phoneValidationError } from "shared/config/error-text";
import { fromApi } from "shared/api";
import { $viewer, Client, clientUpdated } from "@client-app/entities/viewer";

import { putClientItemByClient } from "./api";

export const editClientForm = createForm({
  name: "Edit Client Form",
  validate: createValidator(
    object({
      firstName: string().required(" "),
      lastName: string().required(" "),
      phone: string().phone("US", phoneValidationError),
      email: string().email(" ").required(" "),
    })
  ),
  onSubmit: ({ values }) => editClientFormFx(values),
});

export const Gate = createGate();
export const $showSuccessMessage = createStore(false);

const editClientFormFx = attach({
  effect: createEffect(fromApi(putClientItemByClient)),
  source: $viewer,
  // @ts-ignore
  mapParams: (formData: Client, viewer: Client) => ({
    path: { id: viewer.id },
    body: formData,
  }),
});
export const clientEdited = editClientFormFx.done;
export const $clientEditing = createStore(false);
export const $buttonSaveDisable = createStore(true);

$clientEditing.on(clientEdited, () => true);
$buttonSaveDisable
  .on(editClientForm.onChangeFieldBrowser, () => false)
  .reset(editClientFormFx.done);

$showSuccessMessage
  .on(clientEdited, () => true)
  .reset(editClientForm.onChangeFieldBrowser);

sample({
  source: clientEdited,
  fn: ({ result }) => result,
  target: clientUpdated,
});

export const $formSubmitting = putClientItemByClient.pending;
sample({
  clock: Gate.open,
  source: $viewer,
  fn: (data: Client) =>
    data && {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
    },
  target: editClientForm.$values,
});
