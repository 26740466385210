import React, { forwardRef, Ref } from "react";
import { Tooltip } from "@mui/material";
import styled from "styled-components";

import { ReactComponent as QIcon } from "./question.svg";

export function CheckboxWithQ(props: {
  children: React.ReactNode;
}): JSX.Element {
  return <CheckboxWithQRoot>{props.children}</CheckboxWithQRoot>;
}

export function QTooltip(props: { children: string }): JSX.Element {
  return (
    <TooltipContent
      as={Tooltip}
      title={props.children}
      placement="top"
      arrow
      enterTouchDelay={50}
    >
      <Question />
    </TooltipContent>
  );
}

const TooltipContent = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
`;
const Question = styled(
  forwardRef((props, ref: Ref<HTMLElement>) => (
    <span ref={ref}>
      <QIcon {...props} />
    </span>
  ))
)`
  color: #2d4fa1;
  margin-top: -3px;
  --size: 20px;
  width: var(--size);
  height: var(--size);
  cursor: pointer;
`;

const CheckboxWithQRoot = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${Question} {
    margin-top: 4px;
  }
`;
