import { createEffect, createEvent, createStore, sample } from "effector";

import { apiRequestFx } from "shared/api";
import { $paymentsMethods } from "@client-app/entities/payments-methods";

import { $reservationId } from "./_base";

export const $selectedPaymentMethod = createStore<number | null>(null);
export const payBySavedMethodClicked = createEvent<number>();

$selectedPaymentMethod.on(
  payBySavedMethodClicked,
  (_, paymentMethodId) => paymentMethodId
);

export const payBySavedPaymentMethodFx = createEffect(
  async ({
    reservationId,
    paymentMethodId,
  }: {
    paymentMethodId: number;
    reservationId: number;
  }) => {
    await apiRequestFx({
      method: "POST",
      path: `/api/client/reservations/${reservationId}/pay-by`,
      body: {
        paymentMethodId,
      },
    });
  }
);

sample({
  clock: payBySavedMethodClicked,
  source: $reservationId,
  fn: (reservationId, paymentMethodId) => ({ reservationId, paymentMethodId }),
  target: payBySavedPaymentMethodFx,
});

export { $paymentsMethods };
export const $isPaymentInProgress = payBySavedPaymentMethodFx.pending;
