import { useState } from "react";
import styled from "styled-components";
import { Tab, Tabs } from "@mui/material";

import { AuthModalTemplate } from "@client-app/shared/ui/templates/auth-modal-template";
import { SignInForm, SignUpForm } from "@client-app/features/authentication";
import { useMedia } from "shared/ui/styles/media";

interface Props {
  onClose(): void;
  isOpened: boolean;
}

export function LoginModal(props: Props): JSX.Element {
  const [tabIndex, handleChange] = useState(0);

  const { isPhone } = useMedia();

  return (
    <AuthModalTemplate
      isOpened={props.isOpened}
      onClose={props.onClose}
      renderContent={() => (
        <>
          <ModalTabs
            centered={isPhone}
            value={tabIndex}
            onChange={(_, index) => handleChange(index)}
          >
            <ModalTab label="Sign In" />
            <ModalTab label="Sign Up" />
          </ModalTabs>

          {tabIndex === 0 && <SignInForm />}
          {tabIndex === 1 && <SignUpForm />}
        </>
      )}
    />
  );
}
const ModalTab = styled(Tab)``;

const ModalTabs = styled(Tabs)`
  margin-bottom: 30px;
`;
