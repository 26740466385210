import { createForm } from "effector-react-form";

import { object, ref, string } from "yup";

import { attach, sample } from "effector";

import {
  phoneValidationError,
  requiredFieldValidationError,
} from "shared/config/error-text";

import { $reservation, $reservationId, paymentFormDomain } from "./_base";
import { createValidator } from "../../../../shared/lib/form";

import { changeReservationBillingInfoFx } from "../../../entities/reservation";
import { $viewer, Client } from "../../../entities/viewer";

const requiredString = string()
  .nullable()
  .required(requiredFieldValidationError);

export const billingForm = createForm({
  domain: paymentFormDomain,
  initialValues: {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
  },
  validate: createValidator(
    object({
      firstName: string().required(requiredFieldValidationError),
      lastName: string().required(requiredFieldValidationError),
      email: string()
        .email(" ")
        .required(requiredFieldValidationError)
        .oneOf([ref("confirmEmail")], "Emails must match"),
      confirmEmail: string()
        .email()
        .required(requiredFieldValidationError)
        .oneOf([ref("email")], "Emails must match"),
      address1: requiredString,
      city: requiredString,
      state: requiredString,
      zipCode: requiredString,
      phone: string()
        .phone("US", phoneValidationError)
        .required(requiredFieldValidationError),
    })
  ),
  onSubmit: async ({ values }) => {
    await submitBillingDataFx(values);
  },
});

export const $billingSubmitted = paymentFormDomain.createStore(false);

export const submitBillingDataFx = attach({
  effect: changeReservationBillingInfoFx,
  source: $reservationId,
  mapParams(billing: typeof billingForm, reservationId) {
    return {
      reservationId,
      billingInfo: {
        ...billing,
        zipCode: billing.zipCode.toString(),
      },
    };
  },
});

$billingSubmitted.on(submitBillingDataFx.done, () => true);

sample({
  clock: $reservation,
  source: $viewer,
  fn: (viewer: Client) => ({
    firstName: viewer.firstName,
    lastName: viewer.lastName,
    email: viewer.email,
    confirmEmail: viewer?.email,
    address1: viewer.address.address1,
    address2: viewer.address.address2,
    city: viewer.address.city,
    state: viewer.address.state,
    zipCode: viewer.address?.zipCode,
    phone: viewer.phone,
  }),
  target: billingForm.setValues,
});

export const $isBillingSaving = submitBillingDataFx.pending;
