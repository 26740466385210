import { attach, createEffect, createStore, sample } from "effector";
import { createGate } from "effector-react";
import { createRouteParamStore } from "shared/lib/effector-router-params";
import { fromApi, getCityItem } from "shared/api";
import { City } from "shared/api/types";

import { Dock } from "shared/api/types";
import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";
import { getDockCollection, getListFromApi } from "shared/api";

export const cityPageGate = createGate();

const $cityId = createRouteParamStore({
  name: "cityId",
  gate: cityPageGate,
});

export const $city = createStore<City | null>(null);

const getCityInfoFX = attach({
  effect: createEffect(fromApi(getCityItem)),
  source: $cityId,
  mapParams: (_, cityId) => ({ path: { id: cityId as string } }),
});

$city.on(getCityInfoFX.doneData, (_, data) => data).reset(cityPageGate.close);

sample({
  clock: cityPageGate.open,
  target: getCityInfoFX,
});

export const $topDocks = createStore<Dock[]>([]);

const getTopDocksFx = attach({
  // @ts-ignore
  effect: createEffect(getListFromApi(getDockCollection)),
  mapParams: () => ({
    query: {
      page: 1,
      itemsPerPage: 3,
      cityId: $cityId.getState() || "",
    },
  }),
});

export const $isTopDocksLoading = getTopDocksFx.pending;

spreadListDataData({
  $items: $topDocks,
  effect: getTopDocksFx,
});

pagination({
  $page: createStore(1),
  // @ts-ignore
  effect: getTopDocksFx,
  gate: cityPageGate,
  $filters: createStore({}),
});
