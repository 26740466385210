import styled from "styled-components";
import { useUnit } from "effector-react";

import { SmallModalTemplate } from "shared/ui/modals";
import { Button, LinkButton } from "shared/ui/button";
import { PhoneIcon, MailIcon } from "shared/ui/icons";
import { PhoneNumber } from "shared/ui/phone-number";
import { Dock } from "shared/api/types";
import { createOpenAbleState } from "shared/lib/effector-openable-state";

import { ReactComponent as SuccessIcon } from "./success-icon.svg";
import { ReactComponent as NotFitIcon } from "./not-fit-icon.svg";
import { ReactComponent as WrongIcon } from "./wrong-icon.svg";

export const [$isSuccessModalOpened, successModalActions] =
  createOpenAbleState();
export const [timeSlotIsAlreadyTakenModal, timeSlotIsAlreadyTakenModalActions] =
  createOpenAbleState();
export const [$isSomeWrongModalOpened, someWrongModalActions] =
  createOpenAbleState();

export function SuccessModal(props: { dock: Dock }): JSX.Element {
  const isSuccessModalOpened = useUnit($isSuccessModalOpened);

  return (
    <ModalRoot
      isOpened={isSuccessModalOpened}
      renderContent={() => (
        <>
          <Icon as={SuccessIcon} />
          <Title>Your booking is successful!</Title>
          <Message>
            If you have questions or concerns, you can contact the dock manager:
          </Message>

          <DockContacts dock={props.dock} />
        </>
      )}
      renderActions={() => (
        <LinkButton to="/reservations-list" onClick={successModalActions.close}>
          Continue
        </LinkButton>
      )}
    />
  );
}

export function TimeSlotIsAlreadyTakenModal(): JSX.Element {
  const isNotFitModalOpened = useUnit(timeSlotIsAlreadyTakenModal);
  return (
    <ModalRoot
      isOpened={isNotFitModalOpened}
      onClose={timeSlotIsAlreadyTakenModalActions.close}
      renderContent={() => (
        <>
          <Icon as={NotFitIcon} />
          <Title>This time slot is already taken</Title>
          <div>
            Someone has already made a reservation in this time slot, please
            choose another time
          </div>
        </>
      )}
      renderActions={() => (
        <Button onClick={timeSlotIsAlreadyTakenModalActions.close}>Ok</Button>
      )}
    />
  );
}

export function SomethingWrongModal(props: { dock: Dock }): JSX.Element {
  const isSomeWrongModalOpened = useUnit($isSomeWrongModalOpened);

  return (
    <ModalRoot
      isOpened={isSomeWrongModalOpened}
      onClose={someWrongModalActions.close}
      renderContent={() => (
        <>
          <Icon as={WrongIcon} />
          <Title>Something went wrong!</Title>
          <Message>Please contact the dock manager:</Message>
          <DockContacts dock={props.dock} />
        </>
      )}
      renderActions={() => (
        <Button onClick={someWrongModalActions.close}>Try again</Button>
      )}
    />
  );
}

function DockContacts(props: { dock: Dock }): JSX.Element | null {
  return (
    <Contacts>
      {props.dock.email && (
        <Contact>
          <ContactIcon as={MailIcon} />
          <a href={`mailto:${props.dock.email}`}>{props.dock.email}</a>
        </Contact>
      )}
      <Contact>
        <ContactIcon as={PhoneIcon} />
        <a href={`tel:${props.dock.phone}`}>
          <PhoneNumber phone={props.dock.phone} />
        </a>
      </Contact>
    </Contacts>
  );
}

const Icon = styled.div`
  width: 154px;
  height: 154px;
`;

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0a1128;
`;

const Message = styled.div`
  width: 280px;
`;

const ContactIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  &,
  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #2d4fa1;
  }
`;

const Contacts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
`;

const ModalRoot = styled(SmallModalTemplate)`
  width: 420px;

  ${Icon} {
    margin-bottom: 24px;
  }

  ${Title} {
    margin-bottom: 32px;
  }

  ${Message} {
    margin-bottom: 12px;
  }
`;
