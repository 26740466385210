import { attach, createEffect, createEvent, sample } from "effector";
import { fromApi, putBoatItem } from "shared/api";
import { Boat } from "shared/api/types";

export const setMainClicked = createEvent<Boat>();

const editBoatFx = attach({
  effect: createEffect(fromApi(putBoatItem)),
  // @ts-ignore
  mapParams: (boat: Boat) => ({
    path: { id: boat.id },
    body: { ...boat, isMain: true },
  }),
});

export const boatEdited = editBoatFx.done;

sample({
  clock: setMainClicked,
  target: editBoatFx,
});
