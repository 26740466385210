import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { mediaPhone } from "shared/ui/styles/media";
import { Footer } from "@client-app/shared/ui/footer";

import { Header } from "../header";

interface Props {
  children: React.ReactNode;
}

export function BasePageTemplate(props: Props): JSX.Element {
  return (
    <BasePageTemplateRoot>
      <Header />
      <Content>
        <PageContent>{props.children}</PageContent>
      </Content>
      <Footer />
      <GlobalStyle />
    </BasePageTemplateRoot>
  );
}

const Content = styled.div`
  width: var(--contentWidth);
  min-height: calc(100vh - var(--headerHeight));
  margin: 0 auto;

  ${mediaPhone} {
    padding: 0 16px;
  }
`;

const PageContent = styled.div`
  padding: 32px 0;
`;

const BasePageTemplateRoot = styled.div`
  --contentWidth: 1180px;
  --headerHeight: 88px;

  ${Header} {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  ${mediaPhone} {
    --contentWidth: 100vw;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    ${mediaPhone} {
      --contentPadding: 16px;
    }
  }
`;
