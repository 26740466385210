import { createGate } from "effector-react";
import { attach, createEffect, sample } from "effector";
import { loadStripe } from "@stripe/stripe-js";

import { createRouteParamStore } from "shared/lib/effector-router-params";
import { Reservation } from "shared/api/types";

import { fromApi, getReservationItem } from "shared/api";
import { $reservation } from "@client-app/features/pay-for-reservation";
import { getSavedPaymentsMethodsFx } from "@client-app/entities/payments-methods";

export const pageGate = createGate();

export const stripePromise = loadStripe(process.env.RAZZLE_STRIPE_API_KEY);

export const $reservationId = createRouteParamStore({
  name: "reservationId",
  gate: pageGate,
});

export { $reservation };

const getReservationFx = attach({
  effect: createEffect(fromApi(getReservationItem)),
  source: $reservationId,
  mapParams: (_, reservationId) => ({
    path: { id: reservationId as string },
  }),
});

$reservation
  .on(getReservationFx.doneData, (_, reservation) => reservation as Reservation)
  .reset(pageGate.close);

sample({
  clock: pageGate.open,
  target: [getReservationFx, getSavedPaymentsMethodsFx],
});
