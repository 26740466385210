import styled from "styled-components";
import { useGate, useStoreMap } from "effector-react";

import {
  createFormField,
  Select,
  SelectProps,
} from "shared/ui/ecosystems/forms";

import { $cities, gate } from "../model";

type Props = Omit<SelectProps<string>, "options">;

export function CitySelectorComponent(props: Props): JSX.Element {
  useGate(gate);

  const options = useStoreMap($cities, (cities) =>
    cities.map((city) => ({ label: city.name, value: city["@id"] }))
  );

  const { className, ...selectProps } = props;

  return (
    <Root className={className}>
      <Select {...selectProps} options={options} />
    </Root>
  );
}

const Root = styled.div`
  ${Select} {
    width: 100%;
    margin-bottom: 13px !important;
  }
`;

export const CitySelector = styled(CitySelectorComponent)``;

export const CitySelectorField = createFormField(CitySelector);
