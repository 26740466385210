import { Link } from "react-router-dom";
import styled from "styled-components";

import { mediaPhone } from "shared/ui/styles/media";

// @ts-ignore
import logo from "./built-by-devvela.white.png";

export function Footer(): JSX.Element {
  return (
    <FooterRoot>
      <FooterContent>
        <FooterLinks>
          <Link to="/privacy-policy">Privacy</Link>
          <Link to="/terms-of-use">Terms of Use</Link>
          <a href={process.env.RAZZLE_MANAGER_APP_URL} target="_blank">
            Manager App
          </a>
        </FooterLinks>
        <Copyright>Copyright © {new Date().getFullYear()}</Copyright>
        <a target="_blank" href={"https://devvela.com/"}>
          <DevvelaLogo src={logo}></DevvelaLogo>
        </a>
      </FooterContent>
    </FooterRoot>
  );
}

const DevvelaLogo = styled.img`
  width: 120px;
`;

const FooterLinks = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 64px;

  font-weight: 300;
  font-size: 18px;
  line-height: 29px;

  ${mediaPhone} {
    grid-gap: 40px;
  }
`;

const Copyright = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--contentWidth);
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 16px;
  position: relative;

  ${DevvelaLogo} {
    position: absolute;
    right: 0;
    top: 32px;
    ${mediaPhone} {
      position: relative;
      right: auto;
      top: auto;
    }
  }

  &,
  a {
    color: #fbfcff;
  }

  a {
    text-decoration: underline;
  }
`;

const FooterRoot = styled.div`
  background: #070e54;
  height: 127px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaPhone} {
    height: 190px;
  }
`;
