import styled from "styled-components";

import { Reservation } from "shared/api/types";

import { ReactComponent as PaidIcon } from "./paid.svg";
import { ReactComponent as UnPaidIcon } from "./unpaid.svg";

function PaidTagComponent(props: {
  reservation: Reservation;
  className?: string;
}): JSX.Element {
  const isPaid = props.reservation.status === "paid";
  return (
    <PaidTagRoot isPaid={isPaid} className={props.className}>
      <>
        <PaidTagIcon as={isPaid ? PaidIcon : UnPaidIcon} />
        <PaidTagLabel>{props.reservation.status ?? ""}</PaidTagLabel>
      </>
    </PaidTagRoot>
  );
}

const PaidTagIcon = styled.div`
  width: 16px;
  height: 16px;
`;

const PaidTagLabel = styled.div`
  color: #fbfcff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const PaidTagRoot = styled.div<{ isPaid: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 80px;
  height: 24px;
  padding-left: 8px;
  border-radius: 600px 0 0 600px;
  background-color: ${(props) => (!props.isPaid ? "#de2016" : "#5E88EF")};
  ${PaidTagLabel} {
    padding-right: 8px;
  }
`;

export const PaidTag = styled(PaidTagComponent)``;
