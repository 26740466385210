import { ModalIcon, SmallModalTemplate } from "shared/ui/modals";
import { DirectSendIcon } from "shared/ui/icons";

import { Button } from "shared/ui/button";

export function NewPasswordSuccessModal(props: {
  isOpened: boolean;
  onClose(): void;
}): JSX.Element {
  return (
    <SmallModalTemplate
      onClose={props.onClose}
      isOpened={props.isOpened}
      renderTitle={() => "Password recovery"}
      renderContent={() => (
        <>
          <ModalIcon as={DirectSendIcon} />
          You password was successfully changed
        </>
      )}
      renderActions={({ close }) => (
        <Button onClick={close}>Back to Sign in</Button>
      )}
    />
  );
}
