import { useField } from "shared/lib/form";

import { Autocomplete, AutocompleteProps } from "../atoms/autocomplete";

type FieldProps<T> = Omit<T, "value" | "onChange"> & { name: string };

type Props<T> = FieldProps<AutocompleteProps<T>>;

export function AutocompleteField<T>(props: Props<T>): JSX.Element {
  const field = useField({ name: props.name });
  return (
    <Autocomplete
      {...field.input}
      {...props}
      error={field.isShowError ? field.error : ""}
    />
  );
}
