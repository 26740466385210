import { createGate } from "effector-react";
import { createStore, attach, sample } from "effector";

import { getListFromApi, getReasonCollection } from "shared/api";
import { spreadListDataData } from "shared/lib/effector-paginatoin";

export const reasonsGate = createGate();

interface Reason {
  id: number;
  name: string;
}

export const $reservationReasons = createStore<Reason[]>([]);

export const getReasonsFx = attach({
  // @ts-ignore
  effect: getListFromApi(getReasonCollection),
  mapParams: () => ({}),
});

spreadListDataData({
  $items: $reservationReasons,
  effect: getReasonsFx,
});

sample({
  clock: reasonsGate.open,
  target: getReasonsFx,
});
