import React from "react";
import { RouteConfig } from "react-router-config";
import { redirect } from "shared/lib/react-router";

import { RouterProps } from "react-router";

import { HomePage } from "../pages/";
import { DockListPage } from "../pages/dock-list";
import { DockListByIdPage } from "../pages/dock-list/$dockId";

import { TermsOfUsePage } from "../pages/terms-of-use";
import { BoatsPage } from "../pages/boats";
import { PrivacyPolicyPage } from "../pages/privacy-policy";
import { ReservationsListPage } from "../pages/reservations-list";
import { BecomeAnOperatorPage } from "../pages/become-an-operator/page";
import { ReservationPayPage } from "../pages/reservations-list/$reservationsId/pay/page";
import { ProfileEditPage } from "../pages/profile";

import { BasePageTemplate } from "../widgets/base-page-template";
import { CityPage } from "../pages/cities/$cityId";
import { DockTermsPage } from "../pages/dock-list/$dockId/terms-of-use";

const commonRoutes = [
  {
    exact: true,
    path: "/",
    component: HomePage,
  },
  {
    exact: true,
    path: "/cities/:cityId",
    component: CityPage,
  },
  {
    exact: true,
    path: "/become-an-operator",
    component: BecomeAnOperatorPage,
  },
  {
    exact: true,
    path: "/dock-list",
    component: withBaseTemplate(DockListPage),
  },
  {
    exact: true,
    path: "/dock-list/:dockId",
    component: withBaseTemplate(DockListByIdPage),
  },
  {
    exact: true,
    path: `/dock-list/:dockId/terms-of-use`,
    component: withBaseTemplate(DockTermsPage),
  },
  {
    exact: true,
    path: "/privacy-policy",
    component: withBaseTemplate(PrivacyPolicyPage),
  },
  {
    exact: true,
    path: "/terms-of-use",
    component: withBaseTemplate(TermsOfUsePage),
  },
];

export const noAuthRoutes: RouteConfig[] = [
  ...commonRoutes,
  {
    component: redirect("/"),
  },
];

export const authRoutes: RouteConfig[] = [
  ...commonRoutes,

  {
    exact: true,
    path: "/boats",
    component: withBaseTemplate(BoatsPage),
  },

  {
    path: "/reservations-list",
    component: withBaseTemplate(ReservationsListPage),
    exact: true,
  },
  {
    path: "/profile",
    component: withBaseTemplate(ProfileEditPage),
    exact: true,
  },
  {
    path: "/reservations-list/:reservationId/pay",
    component: withBaseTemplate(ReservationPayPage),
    exact: true,
  },
  {
    component: redirect("/"),
  },
];

function withBaseTemplate(component: React.FunctionComponent<RouterProps>) {
  return function routerPage(props: RouterProps) {
    return React.createElement(BasePageTemplate, {
      children: React.createElement(component, props),
    });
  };
}
