import styled from "styled-components";
import { useGate, useStore } from "effector-react";

import {
  FormAuthTemplate,
  FormFields,
} from "shared/ui/templates/auth-form-template";
import {
  SubmitButton,
  InputField,
  PhoneField,
  CheckBoxField,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";
import { signUpApiErrorsByStatuses } from "shared/api";

import {
  $serverErrorStatus,
  $isFormSubmitting,
  signUpForm,
  signUpFormGate,
} from "../model";

export function SignUpForm(): JSX.Element {
  useGate(signUpFormGate);
  const isSubmitting = useStore($isFormSubmitting);
  const errorStatus = useStore($serverErrorStatus);

  return (
    <Form form={signUpForm}>
      <FormAuthTemplate
        header="Create account"
        subHeader="Almost there! Enter the information below to create a free account."
        error={
          errorStatus &&
          (signUpApiErrorsByStatuses[errorStatus] ?? " Unknown error")
        }
        renderContent={() => (
          <FormFields>
            <Name>
              <InputField
                name="firstName"
                label="First Name"
                placeholder="Enter first name"
              />
              <InputField
                name="lastName"
                type="text"
                label="Last Name"
                placeholder="Enter last name"
              />
            </Name>

            <PhoneField name="phone" label="Phone Number" />
            <InputField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter email"
            />
            <InputField
              label="Password"
              name="password"
              type="password"
              placeholder="Enter password"
            />
            <Rules>
              <CheckBoxField align="start" name="agreeWithRules">
                I agree with the{" "}
                <a target="_blank" href="/privacy-policy">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="/terms-of-use" target="_blank">
                  Terms and Conditions
                </a>
              </CheckBoxField>
            </Rules>
          </FormFields>
        )}
        renderAction={() => (
          <Actions>
            <SubmitButton isSubmitting={isSubmitting}>Sign Up</SubmitButton>
          </Actions>
        )}
      />
    </Form>
  );
}

const Name = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`;

const Rules = styled.div`
  margin-top: 22px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;
