import { render } from "react-dom";

import { App } from "./app/app";
import { getUserDataFx } from "./entities/viewer/model";

(async () => {
  try {
    await getUserDataFx({});
  } finally {
    render(<App />, document.getElementById("root"));
  }
})();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (module.hot) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  module.hot.accept();
}
