import { Dock } from "../index";

export function getDockPricesRange(dock: Dock): { min: number; max: number } {
  const prices = [dock.defaultPrice, ...dock.prices.map((item) => item.price)];
  const max = Math.max(...prices);
  const min = Math.min(...prices);
  return { min, max };
}

export function getDockMinPrice(dock: Dock): number {
  const prices = [dock.defaultPrice, ...dock.prices.map((item) => item.price)];
  return Math.min(...prices);
}
