import { createGate } from "effector-react";
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";

import { City } from "shared/api/types";
import { getCityCollection, getListFromApi } from "shared/api";

export const homePageGate = createGate();

export const $cities = createStore<City[]>([]);
export const $totalCities = createStore<number | null>(null);

export const setPage = createEvent<number>();
export const $page = createStore(1).on(setPage, (_, page) => page);

export const setPerPage = createEvent<number>();
export const $perPage = createStore(3).on(setPerPage, (_, perPage) => perPage);

const getCitiesFx = attach({
  // @ts-ignore
  effect: createEffect(getListFromApi(getCityCollection)),
  mapParams: () => ({
    query: {
      page: $page.getState(),
      itemsPerPage: $perPage.getState(),
    },
  }),
});

$cities.on(getCitiesFx.doneData, (oldItems, { items }) =>
  $page.getState() > 1 ? [...oldItems, ...items] : items
);

$totalCities.on(getCitiesFx.doneData, (_, { totalCount }) => totalCount);

sample({
  clock: homePageGate.open,
  source: $page,
  target: getCitiesFx,
});

sample({
  clock: $page,
  source: $page,
  target: getCitiesFx,
});

sample({
  clock: $perPage,
  source: $perPage,
  target: getCitiesFx,
});
