import { useRef } from "react";
import styled from "styled-components";
import { useStore } from "effector-react";
import { Box, IconButton, Popper } from "@mui/material";

import { Form } from "shared/lib/form";
import { FeedBackIcon } from "shared/ui/icons";
import { CloseButton } from "shared/ui/modals";

import {
  FormError,
  InputField,
  SubmitButton,
  TextAreaField,
} from "shared/ui/ecosystems/forms";

import {
  feedbackForm,
  $formSubmitting,
  $serverErrorStatus,
  sendFeedback,
  $isSendFeedBackPopupOpened,
  closePopup,
  $isSuccessModalOpened,
  closeSuccessModal,
} from "../model";
import { FeedBackFormSuccessModal } from "./feedback-form-success-modal";

function FeedbackButtonComponent(props: { className?: string }): JSX.Element {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const open = useStore($isSendFeedBackPopupOpened);
  const isSuccessModalOpened = useStore($isSuccessModalOpened);

  return (
    <div className={props.className}>
      <IconButton ref={buttonRef} onClick={() => sendFeedback()}>
        <Icon as={FeedBackIcon} />
      </IconButton>

      <Popper placement="top-end" open={open} anchorEl={buttonRef.current}>
        <FeedbackForm />
      </Popper>

      <FeedBackFormSuccessModal
        isOpened={isSuccessModalOpened}
        onClose={closeSuccessModal}
      />
    </div>
  );
}

function FeedbackForm(): JSX.Element {
  const isSubmitting = useStore($formSubmitting);
  const errorStatus = useStore($serverErrorStatus);
  // todo Error message?
  const error = (errorStatus && "Error message") ?? "Unknown error";
  return (
    <FormRoot>
      <CloseButton onClick={closePopup} />
      <Title>Feedback</Title>
      {errorStatus && <FormError>{error}</FormError>}
      <Form form={feedbackForm}>
        <FormFields>
          <InputField name="name" type="string" placeholder="Enter name" />

          <InputField name="email" type="email" placeholder="Enter email" />

          <TextAreaField
            name="text"
            placeholder="Write a feedback"
            minRows={3}
            maxRows={3}
          />
        </FormFields>
        <Actions>
          <SubmitButton isSubmitting={isSubmitting}>Send</SubmitButton>
        </Actions>
      </Form>
    </FormRoot>
  );
}

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`;

export const FormFields = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 33px;
`;

const Actions = styled.div`
  ${SubmitButton} {
    width: 100%;
  }
`;

const FormRoot = styled.div`
  width: 312px;
  border-radius: 16px;
  padding: 16px;
  background: #f7f9fc;
  position: relative;

  :after {
    z-index: -1;
    content: "";
    position: absolute;
    left: 258px;
    bottom: -6px;
    height: 25px;
    width: 25px;
    transform: rotate(45deg);
    border-radius: 4px;
    background: #f7f9fc;
  }

  ${CloseButton} {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  ${Title} {
    margin-bottom: 44px;
  }

  ${FormFields} {
    margin-bottom: 32px;
  }
`;

const Icon = styled.div`
  width: 64px;
  height: 64px;
`;

export const FeedbackButton = styled(FeedbackButtonComponent)``;
