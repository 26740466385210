import styled from "styled-components";

import { ModalIcon, SmallModalTemplate } from "shared/ui/modals";
import { DocksIcon } from "shared/ui/icons";
import { Button } from "shared/ui/button";

export function DeleteBoatErrorModal(props: {
  isOpened: boolean;
  onClose(): void;
}): JSX.Element {
  return (
    <SmallModalTemplate
      onClose={props.onClose}
      isOpened={props.isOpened}
      renderTitle={() => "Boat can’t be deleted."}
      renderContent={() => (
        <>
          <ModalIcon as={RedDocksIcon} />
          There are future reservations related to this boat.
        </>
      )}
      renderActions={({ close }) => <Button onClick={close}>Ok</Button>}
    />
  );
}

const RedDocksIcon = styled(DocksIcon)`
  width: 64px;
  height: 64px;
`;
