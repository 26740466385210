import { attach, createEffect, createStore, sample } from "effector";
import { createGate } from "effector-react";
import { createRouteParamStore } from "shared/lib/effector-router-params";
import { fromApi, getDockItem } from "shared/api";
import { Dock } from "shared/api/types";

import { $dock as $dockForReservation } from "@client-app/features/reserv-dock";

export const dockPageGate = createGate();

const $dockId = createRouteParamStore({
  name: "dockId",
  gate: dockPageGate,
});

export const $dock = createStore<Dock | null>(null);

const getDockInfoFX = attach({
  effect: createEffect(fromApi(getDockItem)),
  source: $dockId,
  mapParams: (_, dockId) => ({ path: { id: dockId as string } }),
});

$dock.on(getDockInfoFX.doneData, (_, data) => data).reset(dockPageGate.close);

sample({
  clock: dockPageGate.open,
  target: getDockInfoFX,
});

sample({
  clock: $dock,
  target: $dockForReservation,
});
