import styled from "styled-components";
import { useGate, useStore } from "effector-react";

import {
  $isTimeUp,
  ReservationPayment,
  Timer,
} from "@client-app/features/pay-for-reservation";
import { mediaDesktop, mediaPhone } from "shared/ui/styles/media";
import { LinkButton } from "shared/ui/button";

import { $reservation, pageGate } from "./model";

export function ReservationPayPage(): JSX.Element | null {
  useGate(pageGate);

  const isTimeUp = useStore($isTimeUp);
  const reservation = useStore($reservation);

  if (!reservation) {
    return null;
  }

  if (isTimeUp) {
    return (
      <ReservationPayPageRoot>
        <ExpiredTime>
          <PageTitle>Payment time has expired</PageTitle>
          <LinkButton to={`/dock-list/${reservation.dock.id}`}>
            Book Again
          </LinkButton>
        </ExpiredTime>
      </ReservationPayPageRoot>
    );
  }

  return (
    <ReservationPayPageRoot>
      <PageTitle>
        Reservation <Timer />
      </PageTitle>

      <ReservationPayment />
    </ReservationPayPageRoot>
  );
}

const PageTitle = styled.div`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  display: flex;

  ${mediaDesktop} {
    justify-content: space-between;
  }

  ${mediaPhone} {
    flex-direction: column;

    ${Timer} {
      align-self: center;
      margin-top: 32px;
    }
  }
`;

const PageSubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #969899;
`;

const ExpiredTime = styled.div`
  display: flex;
  text-align: center;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;

  ${LinkButton} {
    width: 200px;
  }
`;

const ReservationPayPageRoot = styled.div`
  margin: 0 auto;

  ${PageTitle} {
    margin-bottom: 12px;
  }

  ${PageSubTitle} {
    margin-bottom: 40px;
  }

  ${mediaPhone} {
    width: auto;
    text-align: left;
  }
`;
