import { createGate } from "effector-react";
import { sample } from "effector";

import { getClientsBoatsFx } from "@client-app/entities/boats";
import { boatEdited } from "@client-app/features/manage-boats/edit";

export const pageGate = createGate();

sample({
  clock: [pageGate.open, boatEdited],
  target: getClientsBoatsFx,
});
