import { createEffect } from "effector";

import {
  PutClientItem,
  putClientItemBadRequest,
  PutClientItemDone,
  PutClientItemFail,
  putClientItemNotFound,
  putClientItemOk,
  putClientItemUnprocessableEntity,
} from "shared/api";
import { parseByStatus, requestFx } from "shared/api/request";

export const putClientItemByClient = createEffect<
  PutClientItem,
  PutClientItemDone,
  PutClientItemFail
>({
  async handler({ body, path }) {
    const name = "putClientItemByClient.body";
    const response = await requestFx({
      path: `/api/clients/${path.id}/by-client`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putClientItemOk],
      400: ["bad_request", putClientItemBadRequest],
      404: ["not_found", putClientItemNotFound],
      422: ["unprocessable_entity", putClientItemUnprocessableEntity],
    });
  },
});
