import styled from "styled-components";
import { ReactNode } from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DowntownLogo } from "shared/ui/downtown-logo";

import { mediaDesktop, mediaPhone, useMedia } from "shared/ui/styles/media";

import back from "./back.jpg";
import backMobile from "./back-mobile.jpg";

interface Props {
  isOpened: boolean;
  onClose(): void;
  renderContent(): ReactNode;
}

export function AuthModalTemplate(props: Props): JSX.Element {
  const { isDesktop } = useMedia();

  return (
    <AuthDialog open={props.isOpened} fullScreen={!isDesktop}>
      <IconButton
        aria-label="close"
        onClick={() => props.onClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogPaper>
        <WelcomeScreen>
          <DowntownLogo />
          <WelcomeTitle>Welcome to Downtown Docks!</WelcomeTitle>
        </WelcomeScreen>
        <Content>{props.renderContent()}</Content>
      </DialogPaper>
    </AuthDialog>
  );
}

const WelcomeTitle = styled.div`
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #fbfcff;
`;

const WelcomeScreen = styled.div`
  padding-top: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${DowntownLogo} {
    width: 140px;
    height: 44px;
    margin-bottom: 64px;
  }
`;

const Content = styled.div`
  background: #f5fafa;
  ${mediaDesktop} {
    border-radius: 80px 0 0 80px;
    padding: 30px 76px 0 48px;
  }

  ${mediaPhone} {
    border-radius: 48px 48px 0 0;
    padding: 12px 16px 80px;
    flex: 1;
  }
`;

const DialogPaper = styled(DialogContent)`
  background-repeat: no-repeat;
  background-size: cover;
  ${mediaDesktop} {
    background-image: url("${back}");
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  ${mediaPhone} {
    background-image: url("${backMobile}");

    ${WelcomeScreen} {
      margin-bottom: 88px;
    }
  }
`;

const AuthDialog = styled(Dialog)`
  ${mediaDesktop} {
    .MuiDialog-paper {
      width: 1000px;
      max-width: 1000px;
      height: 700px;
      border-radius: 20px;
    }
  }

  .MuiDialogContent-root {
    padding: 0;
    height: 100%;
    ${mediaPhone} {
      display: flex;
      flex-direction: column;
    }
  }

  ${mediaPhone} {
    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
    }
  }
`;
