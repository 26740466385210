import styled from "styled-components";
import { useStore } from "effector-react";

import {
  FormAuthTemplate,
  FormFields,
} from "shared/ui/templates/auth-form-template";
import { SubmitButton, InputField } from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";

import {
  $isFormSubmitting,
  $serverErrorStatus,
  newPasswordForm,
} from "../models/new-password-form-model";

const errorsByStatus = {
  400: "Invalid input",
  404: "Incorrect or confirmed token",
};

export function NewPasswordForm(): JSX.Element {
  const isFormSubmitting = useStore($isFormSubmitting);
  const serverErrorStatus = useStore($serverErrorStatus);

  return (
    <>
      <Form form={newPasswordForm}>
        <FormAuthTemplate
          error={
            serverErrorStatus &&
            (errorsByStatus[serverErrorStatus] ?? "Unknown error")
          }
          header="New Password"
          subHeader="Please create a new password"
          renderContent={() => (
            <FormFields>
              <InputField
                name="password"
                type="password"
                label="New password *"
                placeholder="Enter new password"
              />
            </FormFields>
          )}
          renderAction={() => (
            <Actions>
              <SubmitButton isSubmitting={isFormSubmitting}>
                Save new password
              </SubmitButton>
            </Actions>
          )}
        />
      </Form>
    </>
  );
}

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;
