import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { MetaTags } from "shared/lib/metaTags";
import { Boat } from "shared/api/types";
import { ConfirmModal, useConfirmModal } from "shared/ui/modals";
import { PlusIcon, TrashIcon } from "shared/ui/icons";
import {
  createMediaMaxWidth,
  mediaPhone,
  useMedia,
} from "shared/ui/styles/media";
import { Button } from "shared/ui/button";

import { BoatCard } from "entities/boats";
import { $clientsBoats } from "@client-app/entities/boats";
import {
  AddBoatForm,
  addBoatClicked,
} from "@client-app/features/manage-boats/add";
import { deleteBoat } from "@client-app/features/manage-boats/delete";
import { setMainClicked } from "@client-app/features/manage-boats/edit";
import { DeleteBoatErrorModal } from "@client-app/features/manage-boats/delete/ui/delete-boat-error-modal";
import {
  $isErrorBoatDeleteModalOpened,
  closeErrorModal,
} from "@client-app/features/manage-boats/delete/model";

import { pageGate } from "./model";

export function BoatsPage(): JSX.Element {
  useGate(pageGate);
  const boats = useStore($clientsBoats);
  const isErrorBoatDeleteModalOpened = useStore($isErrorBoatDeleteModalOpened);
  const [confirmDeleting, confirmModalProps] = useConfirmModal(
    (boat: Boat) => {
      deleteBoat(boat.id);
    },
    (boat) => ({
      title: "Delete Boat?",
      Icon: TrashIcon,
      message: <>Are you sure you want to delete {boat.name} ?</>,
      confirmText: "Yes, delete",
    })
  );

  const { isPhone } = useMedia();

  const addButton = (
    <AddButton Icon={PlusIcon} onClick={() => addBoatClicked()}>
      Add a boat
    </AddButton>
  );
  const haveBoats = boats.length > 0;
  return (
    <Root>
      <MetaTags title="Boats" />
      <Header>
        <Title>My boats</Title>
        {!isPhone && haveBoats && addButton}
      </Header>
      {!haveBoats ? (
        <EmptyBoatsList>
          <Notice>
            You haven't saved any boats yet. Start by clicking "Add a boat"
          </Notice>
          {addButton}
        </EmptyBoatsList>
      ) : null}
      <Boats>
        {boats.map((boat) => (
          <BoatCard
            key={boat.id}
            boat={boat}
            onDeleteClick={() => confirmDeleting(boat)}
            onMainClick={() => setMainClicked(boat)}
          />
        ))}
        {isPhone && haveBoats && addButton}
      </Boats>
      <ConfirmModal {...confirmModalProps} />
      <AddBoatForm />
      <DeleteBoatErrorModal
        isOpened={isErrorBoatDeleteModalOpened}
        onClose={closeErrorModal}
      />
    </Root>
  );
}

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #0a1128;

  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;
const Notice = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;
const EmptyBoatsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const AddButton = styled(Button)`
  width: 180px;

  ${mediaPhone} {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Boats = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;

  ${createMediaMaxWidth(1250)} {
    grid-template-columns: 1fr 1fr;
  }

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${Header} {
    margin-bottom: 24px;
  }
  ${EmptyBoatsList} {
    margin-top: 200px;
  }

  ${mediaPhone} {
    ${Header} {
      margin-bottom: 16px;
    }

    ${AddButton} {
      margin-top: 16px;
    }
  }
`;
