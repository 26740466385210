import { attach, createEffect, sample } from "effector";
import { StripeCardNumberElement } from "@stripe/stripe-js/types/stripe-js/elements";

import { getStripeKeyForPayment } from "@client-app/entities/reservation";
import { stripePromise } from "shared/config/stripe";
import { createToggleState } from "shared/lib/effector-states";

import { $reservationId, paymentFormDomain } from "./_base";

export const paymentFormSubmitted =
  paymentFormDomain.createEvent<StripeCardNumberElement>();

export const [$savePaymentMethod, savePaymentMethodToggle] = createToggleState({
  domain: paymentFormDomain,
});

export const getStripeKeyFx = attach({
  effect: getStripeKeyForPayment,
  source: $reservationId,
  mapParams({ savePaymentMethod }, reservationId: string) {
    return {
      reservationId,
      savePaymentMethod,
    };
  },
});

const $secretKey = paymentFormDomain.createStore(null);

$secretKey.on(getStripeKeyFx.doneData, (_, value) => value);

export const payWithNewPaymentMethodFx = createEffect(
  async ({
    card,
    savePaymentMethod,
    secretKey,
  }: {
    card: StripeCardNumberElement;
    savePaymentMethod: boolean;
    secretKey: string | null;
  }) => {
    const stripe = await stripePromise;
    const clientSecret =
      secretKey ||
      (await getStripeKeyFx({
        savePaymentMethod,
      }));
    const result = await stripe?.confirmCardPayment?.(clientSecret as string, {
      payment_method: {
        card,
      },
    });
    if (result?.error) {
      throw result.error;
    }
  }
);

sample({
  clock: paymentFormSubmitted,
  source: { savePaymentMethod: $savePaymentMethod, secretKey: $secretKey },
  fn: ({ savePaymentMethod, secretKey }, card) => ({
    card,
    savePaymentMethod,
    secretKey,
  }),
  target: payWithNewPaymentMethodFx,
});

export const $isPaymentSent = payWithNewPaymentMethodFx.pending;
