import { Form } from "shared/lib/form";
import { CheckboxWithQ, QTooltip } from "shared/ui/tooltip";
import { CheckBoxField } from "shared/ui/ecosystems/forms";

import { useUnit } from "effector-react";

import { CheckBox } from "shared/ui/ecosystems/forms";

import styled from "styled-components";

import { ContinueButton } from "./continue-button";
import { FormTemplate } from "./form-template";
import {
  $addons,
  $dockAddons,
  $isAddonsSaving,
  addonClicked,
  addonsForm,
} from "../model/addons";

export function AddonsForm(): JSX.Element {
  const [isAddonsSaving, dockAddons, addons] = useUnit([
    $isAddonsSaving,
    $dockAddons,
    $addons,
  ]);

  return (
    <Form form={addonsForm}>
      <FormTemplate
        renderFields={() => (
          <Root>
            <CheckboxWithQ>
              <CheckBoxField name="isCharter">
                <AddonName>Is this a charter?</AddonName>
              </CheckBoxField>
              <QTooltip>
                A charter fee of $100 must be collected for all charters using
                our docks for any reason. This is a City of Chicago requirement.
                Any vessel or customer that is not cooperating with this policy
                will result in a permanent ban..
              </QTooltip>
            </CheckboxWithQ>
            {dockAddons.length > 0 && (
              <>
                {dockAddons.map((addon) => (
                  <AddonLine>
                    <CheckBox
                      value={addons.includes(addon["@id"])}
                      onChange={() => addonClicked(addon["@id"])}
                    >
                      <AddonName>{addon.addon.name}</AddonName>
                    </CheckBox>
                    <AddonDescription>{addon.description}</AddonDescription>
                  </AddonLine>
                ))}
              </>
            )}
          </Root>
        )}
        renderActions={() => (
          <ContinueButton isSubmitting={isAddonsSaving}>
            Continue
          </ContinueButton>
        )}
      />
    </Form>
  );
}

const AddonName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const AddonDescription = styled.div``;

const AddonLine = styled.div`
  display: flex;
  flex-direction: column;
  ${AddonDescription} {
    margin: -12px 0 0 32px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
