import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@mui/material";

import { LocationIcon } from "shared/ui/icons";
import { YachtLengthIcon } from "shared/ui/icons";
import { Dock } from "shared/api/types";
import { mediaPhone } from "shared/ui/styles/media";
import { getDockShortAddress } from "entities/docks/lib/adress";
import { getDockMinPrice } from "entities/docks";

interface Props {
  className?: string;
  dock: Dock;
  onSelect?(): void;
}

function DockCardComponent(props: Props): JSX.Element {
  const dockMinPrice = getDockMinPrice(props.dock as any);

  return (
    <DockCardRoot className={props.className} onClick={props.onSelect}>
      <Header>
        <Name to={`/dock-list/${props.dock.id}`}>{props.dock.name}</Name>
      </Header>
      <Specifications>
        <LocationLine>
          <Icon as={LocationIcon} />
          <Location>{getDockShortAddress(props.dock)}</Location>
        </LocationLine>
        <LengthLine>
          <Icon as={YachtLengthIcon} />
          <Length>{`Max ${props.dock.length} ft`}</Length>
        </LengthLine>
      </Specifications>
      <DockImage src={props.dock.image.url!} alt="" />
      <Footer>
        <PriceLine>
          From
          <Price>{`$${dockMinPrice}`}</Price> per hour
        </PriceLine>
        <BookButton>Book</BookButton>
      </Footer>
    </DockCardRoot>
  );
}

const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

const Name = styled(Link)`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #0a1128;
  text-decoration: none;
  ${mediaPhone} {
    font-size: 18px;
    line-height: 23px;
  }
`;

const Header = styled.div`
  text-align: left;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Specifications = styled.div`
  display: flex;
  justify-content: space-between;
  color: #5e616c;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
`;

const Location = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
`;

const LocationLine = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Length = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
`;

const LengthLine = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Price = styled.div`
  color: #5e88ef;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  ${mediaPhone} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const PriceLine = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #5e616c;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  ${mediaPhone} {
    font-size: 16px;
  }
`;

const BookButton = styled(Button)`
  width: 140px;
  ${mediaPhone} {
    width: 120px;
  }
`;

const DockImage = styled.img`
  width: 100%;
  height: 226px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 24px;
`;

const DockCardRoot = styled.div`
  padding: 24px;
  background: #f1f3fa;
  border-radius: 16px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5e616c;
  cursor: pointer;

  ${mediaPhone} {
    padding: 16px;
  }

  ${Header} {
    margin-bottom: 20px;
  }
  ${Specifications} {
    margin-bottom: 15px;
  }
  ${DockImage} {
    margin-bottom: 24px;
  }
`;

export const DockCard = styled(DockCardComponent)``;
