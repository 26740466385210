import { attach, createStore, sample } from "effector";
import { createGate } from "effector-react";

import { spreadListDataData } from "shared/lib/effector-paginatoin";
import { getListFromApi, getCityCollection } from "shared/api";
import { City } from "shared/api/types";

export const gate = createGate();

export const $cities = createStore<City[]>([]);

export const getCitiesFx = attach({
  // @ts-ignore
  effect: getListFromApi(getCityCollection),
});

spreadListDataData({
  $items: $cities,
  effect: getCitiesFx,
});

sample({
  clock: gate.open,
  target: getCitiesFx,
});
