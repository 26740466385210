import React from "react";
import styled from "styled-components";

export function ContentPageTemplate(props: {
  renderHeader(): React.ReactNode;
  renderSubHeader?(): React.ReactNode;
  content: string;
}): JSX.Element {
  return (
    <div>
      <Header>{props.renderHeader()}</Header>
      {props.renderSubHeader && (
        <SubHeader>
          {props.renderSubHeader()}
          {}
        </SubHeader>
      )}
      <Content dangerouslySetInnerHTML={{ __html: props.content }}></Content>
    </div>
  );
}

const Header = styled.div`
  font-family: "Kanit", sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #0a1128;
`;

const SubHeader = styled.div`
  font-family: "Kanit", sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: #0a1128;
  margin-bottom: 24px;
`;

const Content = styled.div`
  p {
    margin: 8px 0 8px;
  }
`;
