import { createStore, attach, sample, Store } from "effector";
import { createGate, useGate, useUnit } from "effector-react";

import { createRouteParamStore } from "shared/lib/effector-router-params";
import { ContentPageTemplate } from "@client-app/shared/ui/templates/content-page-template";

import { Dock, getDockById } from "entities/docks";

export const pageGate = createGate();

const $dockId = createRouteParamStore({
  name: "dockId",
  gate: pageGate,
});
export const $dock = createStore<Dock | null>(null);

const getDockFx = attach({ effect: getDockById });

$dock.on(getDockFx.doneData, (_, dock) => dock);

sample({
  clock: pageGate.open,
  source: $dockId as Store<string>,
  target: getDockFx,
});

export function DockTermsPage(): JSX.Element {
  useGate(pageGate);
  const dock = useUnit($dock);

  if (!dock) return <></>;

  return (
    <ContentPageTemplate
      renderHeader={() => `Terms of use for ${dock.name}`}
      content={dock.terms}
    />
  );
}
