import { ReactNode } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as Icon } from "./card-icon.svg";
import { PaymentsMethod } from "../../../@client-app/entities/payments-methods";

function PaymentMethodComponent(props: {
  className?: string;
  method: PaymentsMethod;
  renderActions: () => ReactNode;
  disabled?: boolean;
}) {
  return (
    <PaymentMethodRoot className={props.className} $disabled={props.disabled}>
      <Card>
        <CardIcon />
        <div>•••• •••• •••• {props.method.cardLast4}</div>
      </Card>

      <Actions>{props.renderActions()}</Actions>
      {props.disabled && <Disabled />}
    </PaymentMethodRoot>
  );
}

const CardIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

const Card = styled.div`
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #0a1128;
`;

const Actions = styled.div``;

const Disabled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PaymentMethodRoot = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #5e616c;
  border-radius: 50px;
  padding: 4px 4px 4px 16px;

  position: relative;

  ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.2;
    `}
`;

export const PaymentMethod = styled(PaymentMethodComponent)`
  max-width: 380px;
`;
