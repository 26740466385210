import { createDomain, createStore } from "effector";

import { Reservation } from "shared/api/types";

export const paymentFormDomain = createDomain();
export const $reservation = createStore<Reservation | null>(null);
export const $reservationId = $reservation.map(
  (reservation) => reservation?.id
);

export const $hasPaymentIntent = $reservation.map((reservation) =>
  Boolean(reservation?.paymentIntent)
);
export const $dockId = $reservation.map((reservation) => reservation?.dock.id);
