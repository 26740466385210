import { useGate, useStore } from "effector-react";

import { RadioGroupField } from "shared/ui/ecosystems/forms";

import { $reservationReasons, reasonsGate } from "../model/reasons";

interface Props {
  name: string;
}

export function ReasonInput(props: Props): JSX.Element {
  useGate(reasonsGate);
  const reasons = useStore($reservationReasons);

  return (
    <RadioGroupField
      name={props.name}
      options={reasons.map((reason) => ({
        value: reason.id,
        label: reason.name,
      }))}
    />
  );
}

export const ReasonField = ReasonInput;
