import { Effect } from "effector";

import { postUserForgotPassword } from "./models/forgot-password-form-model";
import { postUserForgotPasswordToken } from "./models/new-password-form-model";

export function configure(config: {
  forgotPasswordFx: Effect<any, any, any>;
  createNewPasswordFx: Effect<any, any, any>;
}): void {
  postUserForgotPassword.use(config.forgotPasswordFx);
  postUserForgotPasswordToken.use(config.createNewPasswordFx);
}
