import { createEffect, createStore } from "effector";
import { createForm } from "effector-react-form";
import { object, string } from "yup";
import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore } from "shared/api";

export const postUserForgotPassword = createEffect();

export const forgotPasswordForm = createForm({
  name: "Forgot Password Form",
  initialValues: {
    email: "",
  },
  validate: createValidator(
    object({
      email: string().email(" ").required(" "),
    })
  ),
  onSubmit: ({ values }) => submitFormFx(values),
});

const submitFormFx = createEffect(async (formData: { email: string }) => {
  await postUserForgotPassword({ body: formData });
});

export const $serverErrorStatus = createApiErrorStatusStore(
  postUserForgotPassword
);

export const $isFormSubmitting = postUserForgotPassword.pending;

export const $formSubmittedEmail = createStore<string>("").on(
  submitFormFx.done,
  (_, { params }) => params.email
);
