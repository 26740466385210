import { createEvent, sample } from "effector";
import { createGate } from "effector-react";

import { confirmEvent } from "../../../shared/lib/confirm-modal";
import {
  $paymentsMethods,
  deletePaymentMethodFx,
  getSavedPaymentsMethodsFx,
} from "../../entities/payments-methods";

export const pageGate = createGate();
export const deletePaymentMethodClicked = createEvent<number>();

$paymentsMethods
  .on(getSavedPaymentsMethodsFx.doneData, (_, methods) => methods)
  .on(deletePaymentMethodFx.done, (methods, { params: id }) =>
    methods.filter((method) => method.id !== id)
  );

export const confirmModal = confirmEvent({
  clock: deletePaymentMethodClicked,
  target: deletePaymentMethodFx,
});

sample({
  clock: pageGate.open,
  target: getSavedPaymentsMethodsFx,
});
