import { useStore } from "effector-react";
import styled from "styled-components";

import {
  CheckBoxField,
  FtInputField,
  InputField,
  SubmitButton,
  FormModalTemplate,
} from "shared/ui/ecosystems/forms";
import { mediaPhone } from "shared/ui/styles/media";

import { AutocompleteField } from "shared/ui/ecosystems/forms";

import {
  $isAddBoatModalOpened,
  $isFormSubmitting,
  addBoatForm,
  closeModal,
  $manufacturersList,
  $modelsList,
  $yearsList,
} from "../model";
import { BoatImageField } from "./image-field";

export function AddBoatForm(): JSX.Element {
  const isAddBoatModalOpened = useStore($isAddBoatModalOpened);
  const isFormSubmitting = useStore($isFormSubmitting);
  const manufacturersList = useStore($manufacturersList);
  const modelsList = useStore($modelsList);
  const yearsList = useStore($yearsList);

  return (
    <FormModalTemplate
      form={addBoatForm}
      isOpened={isAddBoatModalOpened}
      onClose={closeModal}
      renderTitle={() => <>Add a boat</>}
      renderSubtitle={() => (
        <>Select a boat from our database or enter your own features</>
      )}
      renderContent={() => (
        <>
          <BoatImageField />
          <FormFields>
            <InputField name="name" label="Enter boat name *" maxLength={30} />
            <AutocompleteField
              name="manufacturer"
              label="Select manufacturer *"
              options={manufacturersList}
            />

            <AutocompleteField
              name="model"
              label="Select model *"
              options={modelsList}
            />
            <AutocompleteField
              name="year"
              label="Select year *"
              options={yearsList}
            />

            <FtInputField name="loa" label="LOA *" min={0} maxLength={3} />
            <FtInputField name="beam" label="Beam *" min={0} maxLength={3} />

            <FtInputField name="draft" label="Draft" />
            <FtInputField name="height" label="Height" />
          </FormFields>

          <CheckBoxField name="isMain">This is my main boat</CheckBoxField>
        </>
      )}
      renderActions={() => (
        <SubmitButton isSubmitting={isFormSubmitting}>Add a boat</SubmitButton>
      )}
    />
  );
}

const FormFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-bottom: 32px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;
