import { createEvent, createStore, Store, Event, Domain } from "effector";

export function createToggleState(params?: {
  domain?: Domain;
}): [Store<boolean>, Event<void>] {
  const $state = createStore(false, { domain: params?.domain });
  const stateToggled = createEvent({ domain: params?.domain });

  $state.on(stateToggled, (state) => !state);

  return [$state, stateToggled];
}
