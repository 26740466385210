import { createEvent, createStore, createEffect } from "effector";

import { getClientMe, fromApi, getClientMeOk } from "shared/api";
import * as typed from "typed-contracts";

export type Client = typed.Get<typeof getClientMeOk>;

export const getUserDataFx = createEffect(fromApi(getClientMe));

export const login = getUserDataFx.doneData;
export const logout = createEvent();

export const clientUpdated = createEvent();

export const $viewer = createStore<Client | null>(null);
export const $hasAuth = $viewer.map(Boolean);
$viewer
  .on(login, (_, data) => data as Client)
  .on(clientUpdated, (_, data) => data)
  .reset(logout);
