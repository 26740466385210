import { createEvent, sample } from "effector";

import { clientCanceledReservationFx } from "../../entities/reservation/api";

export const cancelReservation = createEvent<number>();
export const reservationCanceled = createEvent();

sample({
  clock: cancelReservation,
  target: clientCanceledReservationFx,
});

sample({
  clock: clientCanceledReservationFx.done,
  target: reservationCanceled,
});
