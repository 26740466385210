import styled from "styled-components";
import { useGate, useStore } from "effector-react";
import { Pagination } from "@mui/material";

import { DockCard } from "@client-app/entities/docks/ui/dock-card";
import { SearchInput } from "shared/ui";
import {
  createMediaMaxWidth,
  mediaPhone,
  useMedia,
} from "shared/ui/styles/media";
import { Button } from "shared/ui/button";
import { FilterIcon } from "shared/ui/icons";
import { useOpenableState } from "shared/lib/use-openable-state";
import { MetaTags } from "shared/lib/metaTags";

import {
  $docksTotalCount,
  $currentPage,
  $docksList,
  $itemsPerPage,
  pageChanged,
  pageGate,
  $searchQuery,
  searchQueryChanged,
  dockSelected,
  $isDocksLoading,
} from "./model";
import { Filter } from "./filter";

export function DockListPage(): JSX.Element {
  useGate(pageGate);
  const { isPhone } = useMedia();

  const docksList = useStore($docksList);
  const total = useStore($docksTotalCount);
  const currentPage = useStore($currentPage);
  const itemsPerPage = useStore($itemsPerPage);
  const isDocksLoading = useStore($isDocksLoading);

  function handleChangePage(_: unknown, page: number) {
    pageChanged(page);
  }
  const modalFilter = useOpenableState();
  const pagesCount = Math.ceil(total / itemsPerPage);

  return (
    <>
      <Root>
        <MetaTags title="Docks" />
        <Filter
          isModal={isPhone}
          isOpened={modalFilter.isOpened}
          onClose={() => modalFilter.close()}
        />
        <Content>
          <Header
            total={total}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onClickFilterButton={() => modalFilter.open()}
          />

          {!isDocksLoading && docksList.length === 0 ? (
            <EmptyList>No docks match your search criteria</EmptyList>
          ) : (
            <DocksList>
              {docksList &&
                docksList.map((dock) => (
                  <DockCard
                    key={dock.id as number}
                    dock={dock}
                    onSelect={() => dockSelected(dock.id as number)}
                  />
                ))}
            </DocksList>
          )}

          {pagesCount > 1 && (
            <Footer>
              <Pagination
                color="primary"
                size="large"
                page={currentPage}
                onChange={handleChangePage}
                count={pagesCount}
              />
            </Footer>
          )}
        </Content>
      </Root>
    </>
  );
}

function SearchComponent(): JSX.Element {
  const searchQuery = useStore($searchQuery);
  return (
    <SearchInput
      label="Search"
      value={searchQuery ? searchQuery : ""}
      onChange={searchQueryChanged}
    />
  );
}

function HeaderComponent(props: {
  className?: string;
  onClickFilterButton(): void;
  total: number;
  currentPage: number;
  itemsPerPage: number;
}): JSX.Element {
  const { isPhone } = useMedia();
  const range = {
    from:
      props.total === 0 ? 0 : (props.currentPage - 1) * props.itemsPerPage + 1,
    to: Math.min(props.total, props.currentPage * props.itemsPerPage),
  };

  if (isPhone)
    return (
      <HeaderRoot className={props.className}>
        <HeaderLine>
          <Search />
          <FilterButton
            startIcon={<FilterIcon />}
            onClick={props.onClickFilterButton}
          >
            Filter
          </FilterButton>
        </HeaderLine>
        <HeaderLine>
          <Title>List of Docks</Title>
          <DisplayedRows>
            <Range> {`${range.from} - ${range.to}`} </Range> of
            <Total> {props.total} </Total> results
          </DisplayedRows>
        </HeaderLine>
      </HeaderRoot>
    );
  else
    return (
      <HeaderRoot className={props.className}>
        <Title>LOCATIONS</Title>
        <Search />
      </HeaderRoot>
    );
}

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Search = styled(SearchComponent)`
  width: 320px;
  ${mediaPhone} {
    width: 235px;
  }
`;

const FilterButton = styled(Button)`
  width: 92px;
`;

const Range = styled.div`
  color: #0a1128;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  padding: 0 4px;
`;

const Total = styled.div`
  color: #0a1128;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  padding: 0 4px;
`;

const DisplayedRows = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: #5b5b5b;
`;

const HeaderRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${mediaPhone} {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: stretch;
  }
`;

const HeaderLine = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const Header = styled(HeaderComponent)``;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const DocksList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  ${createMediaMaxWidth(600)} {
    grid-template-columns: 1fr;
    gap: 16px;
    justify-content: center;
  }
`;

const EmptyList = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #5e616c;
  text-align: center;
  padding-top: 150px;
`;

const Content = styled.div`
  width: 100%;
  ${Footer} {
    margin-top: 32px;
  }
`;

const Root = styled.div`
  display: grid;
  grid-template-columns: 380px 1fr;
  align-items: start;
  gap: 20px;
  ${mediaPhone} {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  ${Header} {
    margin-bottom: 24px;
    ${mediaPhone} {
      margin-bottom: 16px;
    }
  }
  ${Filter} {
    margin-top: 72px;
    ${mediaPhone} {
      display: none;
    }
  }
`;
