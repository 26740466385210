import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@mui/material";

import { LocationIcon } from "shared/ui/icons";
import { City } from "shared/api/types";
import { mediaPhone } from "shared/ui/styles/media";

interface Props {
  className?: string;
  city: City;
  onSelect?(): void;
}

function CityCardComponent(props: Props): JSX.Element {
  return (
    <CityCardRoot className={props.className} onClick={props.onSelect}>
      <Header>
        <Name to={`/cities/${props.city.id!}`}>{props.city.name!}</Name>
      </Header>
      <Specifications>
        <LocationLine>
          <Icon as={LocationIcon} />
          <Location>USA, {props.city.state}</Location>
        </LocationLine>
      </Specifications>
      <CityImage src={props.city.heroImage.url!} alt={props.city.name!} />
      <Footer>
        <BookButton>Select City</BookButton>
      </Footer>
    </CityCardRoot>
  );
}

const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

const Name = styled(Link)`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #0a1128;
  text-decoration: none;
  ${mediaPhone} {
    font-size: 18px;
    line-height: 23px;
  }
`;

const Header = styled.div`
  text-align: left;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

const Specifications = styled.div`
  display: flex;
  justify-content: space-between;
  color: #5e616c;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
`;

const Location = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
`;

const LocationLine = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Length = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
`;

const LengthLine = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BookButton = styled(Button)`
  width: 140px;
  ${mediaPhone} {
    width: 120px;
  }
`;

const CityImage = styled.img`
  width: 100%;
  height: 226px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 24px;
`;

const CityCardRoot = styled.div`
  padding: 24px;
  background: #f1f3fa;
  border-radius: 16px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5e616c;
  cursor: pointer;

  ${mediaPhone} {
    padding: 16px;
  }

  ${Header} {
    margin-bottom: 20px;
  }
  ${Specifications} {
    margin-bottom: 15px;
  }
  ${CityImage} {
    margin-bottom: 24px;
  }
`;

export const CityCard = styled(CityCardComponent)``;
