import { createStore, sample } from "effector";
import { createGate, useGate, useStore } from "effector-react";
import styled from "styled-components";

import { MetaTags } from "shared/lib/metaTags";

import { getTermFx } from "../../../@admin-app/pages/terms-and-conditions-edit/model";

const gate = createGate();

const $terms = createStore(``);

$terms.on(getTermFx.doneData, (_, terms) => terms);

sample({
  clock: gate.open,
  target: getTermFx,
});

export function TermsOfUsePage(): JSX.Element {
  useGate(gate);
  const terms = useStore($terms);

  return (
    <div>
      <MetaTags title="Terms of use" />
      <Header>DOWNTOWN DOCKS</Header>
      <SubHeader>TERMS & CONDITIONS</SubHeader>
      <Content dangerouslySetInnerHTML={{ __html: terms }}></Content>
    </div>
  );
}

const Header = styled.div`
  font-family: "Kanit", sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #0a1128;
`;

const SubHeader = styled.div`
  font-family: "Kanit", sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: #0a1128;
  margin-bottom: 24px;
`;

const Content = styled.div`
  p {
    margin: 8px 0 8px;
  }
`;
