import React from "react";

import { addBoatClicked } from "../model";
import { AddBoatForm } from "./add-boat-form";

export interface Props {
  children: ({
    openAddBoatModal,
  }: {
    openAddBoatModal(): void;
  }) => React.ReactNode;
}

export function AddBoat(props: Props): JSX.Element {
  return (
    <>
      {props.children({ openAddBoatModal: addBoatClicked })}
      <AddBoatForm />
    </>
  );
}
