import { createForm } from "effector-react-form";
import { attach, createEffect, createStore } from "effector";
import { object, string } from "yup";
import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore } from "shared/api";

import { createQueryParamStore } from "shared/lib/effector-router-params";

export const postUserForgotPasswordToken = createEffect();

const $token = createQueryParamStore({ name: "token" });

export const newPasswordForm = createForm({
  name: "New Password Form",
  initialValues: {
    password: "",
  },
  validate: createValidator(
    object({
      password: string().required(),
    })
  ),
  onSubmit: ({ values }) => postNewPasswordFx(values),
});

const postNewPasswordFx = attach({
  effect: postUserForgotPasswordToken,
  source: $token,
  mapParams: (values: { password: string }, token) => ({
    body: values,
    path: { token: token as string },
  }),
});

export const $isFormSubmitting = postNewPasswordFx.pending;
export const $serverErrorStatus = createApiErrorStatusStore(postNewPasswordFx);

export const $newPasswordSubmitted = createStore(false).on(
  postNewPasswordFx.done,
  () => true
);
