import { createEvent, createStore, sample, is, Store, Unit } from "effector";
import { reset } from "patronum";

import { createOpenAbleState } from "../effector-openable-state";

export function confirmEvent<T>(params: { clock: Unit<T>; target: Unit<T> }) {
  const confirmed = createEvent();
  const modalState = createOpenAbleState();

  const $params = createStore<T | null>(null);

  const [$isOpened, modalActions] = modalState;

  sample({
    clock: params.clock,
    target: [modalActions.open, $params],
  });

  reset({
    clock: modalActions.close,
    target: $params,
  });

  sample({
    clock: confirmed,
    source: $params as Store<T>,
    target: params.target,
  });

  if (is.effect(params.target)) {
    sample({
      clock: params.target.finally,
      target: modalActions.close,
    });
  } else {
    sample({
      clock: confirmed,
      target: modalActions.close,
    });
  }

  return {
    "@@unitShape": () => ({
      isOpened: $isOpened,
      onCancel: modalActions.close,
      onConfirm: confirmed,
    }),
  };
}
