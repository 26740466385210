import styled from "styled-components";
import { useGate, useStoreMap } from "effector-react";

import {
  createFormField,
  Select,
  SelectProps,
} from "shared/ui/ecosystems/forms";

import { $clientsBoats, Gate } from "../../model";

type Props = Omit<SelectProps<string>, "options"> & {
  onAddBoatClick(): void;
};

export function BoatSelectorComponent(props: Props): JSX.Element {
  useGate(Gate);
  const options = useStoreMap($clientsBoats, (boats) =>
    boats.length > 0
      ? boats.map((boat) => ({
          label: `${boat.name} (${boat.loa} ft)`,
          value: boat["@id"],
        }))
      : [
          {
            value: "",
            label: "You haven't saved any boats yet",
          },
        ]
  );

  const { className, onAddBoatClick, ...selectProps } = props;

  return (
    <Root className={className}>
      <Select
        {...selectProps}
        options={options}
        placeholder={"You haven't saved any boats yet"}
      />

      <AddBoat>
        No boats saved?{" "}
        <AddButton onClick={onAddBoatClick}>Add a boat</AddButton>
      </AddBoat>
    </Root>
  );
}

const AddBoat = styled.div`
  display: flex;
  gap: 8px;
`;

const AddButton = styled.div`
  text-decoration: underline;
  color: #034078;
  cursor: pointer;
`;

const Root = styled.div`
  ${Select} {
    width: 100%;
    margin-bottom: 13px !important;
  }
`;

export const BoatSelector = styled(BoatSelectorComponent)``;

export const BoatSelectorField = createFormField(BoatSelector);
