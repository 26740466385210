import { useStore } from "effector-react";
import { useHistory, useLocation } from "react-router";

import { createQueryParamStore } from "shared/lib/effector-router-params";

import { LoginModal } from "../pages/login/page";
import { ForgotPasswordModal } from "../pages/forgot-password";
import { PasswordRecoveryModal } from "../pages/password-recovery";

const $modalName = createQueryParamStore({
  name: "m",
});

export function ModalRoutes(): JSX.Element | null {
  const modalName = useStore($modalName);

  const location = useLocation();
  const history = useHistory();

  if (!modalName) {
    return null;
  }

  function closeModal() {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("m");
    history.replace({
      search: queryParams.toString(),
    });
  }

  return (
    <>
      <LoginModal isOpened={modalName === "login"} onClose={closeModal} />
      <ForgotPasswordModal
        isOpened={modalName === "forgot-password"}
        onClose={closeModal}
      />
      <PasswordRecoveryModal
        isOpened={modalName === "password-recovery"}
        onClose={closeModal}
      />
    </>
  );
}
