import { createForm } from "effector-react-form";
import { object, string } from "yup";
import { createEffect, createEvent, createStore, sample } from "effector";

import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore, postAdminCollection } from "shared/api";
import axios from "axios";

const PostFeedbackToZendesk = createEffect(async (values: any) => {
  await axios.post(`https://downtowndocks.zendesk.com/api/v2/requests.json`, {
    request: {
      requester: {
        email: values.email,
        name: values.name,
      },
      subject: "Downtown Docks feedback",
      comment: { body: values.text },
    },
  });
});

export const feedbackForm = createForm({
  name: "FeedBack Form",
  initialValues: {
    email: "",
    name: "",
    text: "",
  },
  validate: createValidator(
    object({
      email: string().email(" ").required(" "),
      name: string().required(" "),
      text: string().required(" "),
    })
  ),
  onSubmit: ({ values }) => submitFormFx(values),
});
export const $isSendFeedBackPopupOpened = createStore(false);
export const sendFeedback = createEvent();
export const closePopup = createEvent();
export const $isSuccessModalOpened = createStore(false);

const openSuccessModal = createEvent();
export const closeSuccessModal = createEvent();

export const $formSubmitting = PostFeedbackToZendesk.pending;

const submitFormFx = createEffect(
  async (formData: { email: string; name: string; text: string }) => {
    await PostFeedbackToZendesk(formData);
  }
);

$isSendFeedBackPopupOpened
  .on(sendFeedback, (state) => !state)
  .on([PostFeedbackToZendesk.doneData, closePopup], () => false);

$isSuccessModalOpened
  .on(openSuccessModal, () => true)
  .on(closeSuccessModal, () => false);

export const $serverErrorStatus =
  createApiErrorStatusStore(postAdminCollection);

sample({
  clock: submitFormFx.done,
  target: [closePopup, openSuccessModal],
});
