import { createEffect, createStore } from "effector";
import { createForm } from "effector-react-form";
import { createApiErrorStatusStore, postOperatorRequest } from "shared/api";
import { createValidator } from "shared/lib/form";
import { object, string } from "yup";

const applyForOperatorFormSchema = object({
  firstName: string().required(" "),
  lastName: string().required(" "),
  phone: string().phone("US", " ").required(" "),
  email: string().email(" ").required(" "),
  company: string().required(" "),
  inquiry: string(),
});

const applyForOperatorFx = createEffect(
  async (params: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    company: string;
    inquiry: string;
  }) => {
    await postOperatorRequest({ body: params });
  }
);

export const applyForOperatorForm = createForm({
  name: "Apply to Become an Operator Form",
  initialValues: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    company: "",
    inquiry: "",
  },
  validate: createValidator(applyForOperatorFormSchema),
  onSubmit: ({ values }) => applyForOperatorFx(values),
});

applyForOperatorForm.$form.reset(applyForOperatorFx.doneData);
applyForOperatorForm.$values.reset(applyForOperatorFx.doneData);

export const $serverErrorStatus = createApiErrorStatusStore(applyForOperatorFx);
export const $formSubmitting = applyForOperatorFx.pending;
export const $formSubmitted = createStore(false).on(
  applyForOperatorFx.doneData,
  () => true
);
