import { createEffect } from "effector";
import { createForm } from "effector-react-form";
import { createGate } from "effector-react";

import { object, string } from "yup";
import { createValidator } from "shared/lib/form";

import { createApiErrorStatusStore, postClientLogin } from "shared/api";
import { getUserDataFx } from "@client-app/entities/viewer";

const signInFormSchema = object({
  email: string().email(" ").required(" "),
  password: string().required(" "),
});

const userSignInFx = createEffect(
  async (params: { email: string; password: string }) => {
    await postClientLogin({ body: params });
    await getUserDataFx({});
  }
);
export const signInFormGate = createGate();
export const signInForm = createForm({
  name: "Sign In Form",
  initialValues: {
    email: "",
    password: "",
  },
  validate: createValidator(signInFormSchema),
  onSubmit: ({ values }) => userSignInFx(values),
});

export const $serverErrorStatus = createApiErrorStatusStore(userSignInFx);
export const $formSubmitting = userSignInFx.pending;
$serverErrorStatus.reset(signInFormGate.close);
