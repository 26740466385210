import { createEvent, createStore } from "effector";
import styled from "styled-components";
import { every } from "patronum";
import { useUnit } from "effector-react";

import { PaymentForm, PaymentFormFields } from "shared/ui/payment-form";
import { createToggleState } from "shared/lib/effector-states";
import { CheckboxWithQ, QTooltip } from "shared/ui/tooltip";
import { CheckBox, SubmitButton } from "shared/ui/ecosystems/forms";
import { ReservationPrice } from "@client-app/entities/reservation";
import { useMedia } from "shared/ui/styles/media";

import {
  paymentFormSubmitted,
  $reservation,
  payBySavedMethodClicked,
  $savePaymentMethod,
  savePaymentMethodToggle,
} from "../model";
import { ContinueButton } from "./continue-button";
import { FormTemplate } from "./form-template";
import { $isPaymentSent } from "../model/pay-by-card";
import { $paymentsMethods } from "../../../entities/payments-methods";
import { PaymentMethod } from "../../../../shared/ui/payment-method";
import { paymentFormDomain } from "../model/_base";
import {
  $isPaymentInProgress,
  $selectedPaymentMethod,
} from "../model/pay-by-saved-method";

export function PaymentMethodForm(): JSX.Element {
  const [
    isNewMethodSelected,
    savePaymentMethod,
    paymentDataCompleted,
    isTermsAndConditionsAccepted,
    isMusicPolicyAccepted,
    isTrashPolicyAccepted,
    isAlcoholPolicyAccepted,
    is3StrikesPolicyAccepted,
    isCancellationPolicyAccepted,
    allPoliciesToggled,
    isPaymentSent,
    reservation,
    isPaymentInProgress,
    selectedPaymentMethod,
  ] = useUnit([
    $isNewMethodSelected,
    $savePaymentMethod,
    $paymentDataCompleted,
    $isTermsAndConditionsAccepted,
    $isMusicPolicyAccepted,
    $isTrashPolicyAccepted,
    $isAlcoholPolicyAccepted,
    $is3StrikesPolicyAccepted,
    $isCancellationPolicyAccepted,
    $allPoliciesToggled,
    $isPaymentSent,
    $reservation,
    $isPaymentInProgress,
    $selectedPaymentMethod,
  ]);

  const { isPhone } = useMedia();

  const [paymentsMethods] = useUnit([$paymentsMethods]);

  const showNewMethod = isNewMethodSelected || paymentsMethods.length === 0;

  const isFormCompleted = allPoliciesToggled && paymentDataCompleted;

  return (
    <PaymentForm onSubmit={paymentFormSubmitted}>
      <FormTemplate
        renderFields={() => (
          <>
            <SavedMethods>
              {paymentsMethods.map((method) => (
                <PaymentMethod
                  key={method.id}
                  method={method}
                  disabled={isNewMethodSelected}
                  renderActions={() => (
                    <PaySaveMethodButton
                      onClick={() => payBySavedMethodClicked(method.id)}
                      disabled={!allPoliciesToggled || isPaymentInProgress}
                      isSubmitting={
                        isPaymentInProgress &&
                        selectedPaymentMethod === method.id
                      }
                    >
                      Pay
                    </PaySaveMethodButton>
                  )}
                />
              ))}
            </SavedMethods>
            {paymentsMethods.length > 0 && (
              <CheckBox
                value={isNewMethodSelected}
                onChange={newMethodSelected}
              >
                New payment method
              </CheckBox>
            )}

            <Fields>
              {showNewMethod && (
                <>
                  <PaymentFormFields
                    onComplete={() => paymentDataCompletedChanged(true)}
                    onInComplete={() => paymentDataCompletedChanged(false)}
                  />
                  <CheckBox
                    value={savePaymentMethod}
                    onChange={savePaymentMethodToggle}
                  >
                    Save this payment method
                  </CheckBox>
                </>
              )}
              <Rules>
                <CheckboxWithQ>
                  <CheckBox
                    value={isMusicPolicyAccepted}
                    onChange={musicPolicyToggled}
                  >
                    I understand the policy for music{" "}
                  </CheckBox>
                  <QTooltip>
                    Music should not be played at a volume level where it can be
                    heard from outside of the vessel. If the music is too loud,
                    one of our dock hands will ask you to turn the volume down.
                    Failure to cooperate will result in a permanent ban from our
                    docks.
                  </QTooltip>
                </CheckboxWithQ>
                <CheckboxWithQ>
                  <CheckBox
                    value={isTrashPolicyAccepted}
                    onChange={() => trashPolicyToggled()}
                  >
                    I understand the policy for trash
                  </CheckBox>
                  <QTooltip>
                    Trash removal is not a service we provide at our docks.
                    Failure to cooperate will result in a permanent ban from our
                    docks.
                  </QTooltip>
                </CheckboxWithQ>
                <CheckboxWithQ>
                  <CheckBox
                    value={isAlcoholPolicyAccepted}
                    onChange={() => alcoholPolicyToggled()}
                  >
                    I understand the alcohol policy
                  </CheckBox>
                  <QTooltip>
                    Alcohol (not purchased on premises from the local vendor) is
                    not permitted on any of our docks. Alcohol must remain on
                    your vessel.
                  </QTooltip>
                </CheckboxWithQ>
                <CheckboxWithQ>
                  <CheckBox
                    value={is3StrikesPolicyAccepted}
                    onChange={() => strikesPolicyToggled()}
                  >
                    I understand the 3 strike policy
                  </CheckBox>
                  <QTooltip>
                    We have a strict 3 strike policy. Failing to comply with any
                    of our terms & conditions or policies, after 3 warnings,
                    will result in a permanent ban. NOTE: Serious offenses can
                    result in a permanent ban from a single offense.
                  </QTooltip>
                </CheckboxWithQ>
                <CheckboxWithQ>
                  <CheckBox
                    value={isCancellationPolicyAccepted}
                    onChange={() => cancellationPolicyToggled()}
                  >
                    I Agree to the Cancellation Policy
                  </CheckBox>
                  <QTooltip>
                    All reservations must adhere to our company's strict 72-hour
                    cancellation policy. Customers will be able to cancel at
                    least 72 hours prior to their reservation. To reschedule
                    contact Downtown Docks. The only exception to this policy is
                    when the USCG issues a small-craft advisory for the
                    designated area during the time of the reservation or in the
                    event of severe weather. All rescheduling must be made in
                    writing via email to service@downtowndocks.com.
                  </QTooltip>
                </CheckboxWithQ>

                <CheckBox
                  value={isTermsAndConditionsAccepted}
                  onChange={() => termsAndConditionsToggled()}
                >
                  I accept{" "}
                  <a
                    href={`/dock-list/${reservation.dock.id}/terms-of-use`}
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>
                </CheckBox>
              </Rules>
              {isPhone && (
                <>
                  <Hr />
                  <ReservationPrice
                    reservation={reservation}
                  ></ReservationPrice>
                </>
              )}
            </Fields>
          </>
        )}
        renderActions={() =>
          showNewMethod && (
            <ContinueButton
              isSubmitting={isPaymentSent}
              disabled={!isFormCompleted}
            >
              Confirm
            </ContinueButton>
          )
        }
      />
    </PaymentForm>
  );
}

export const [$isNewMethodSelected, newMethodSelected] = createToggleState({
  domain: paymentFormDomain,
});

const paymentDataCompletedChanged = createEvent<boolean>({
  domain: paymentFormDomain,
});
const $paymentDataCompleted = createStore(false, {
  domain: paymentFormDomain,
}).on(paymentDataCompletedChanged, (_, newValue) => newValue);

export const [$isTermsAndConditionsAccepted, termsAndConditionsToggled] =
  createToggleState({
    domain: paymentFormDomain,
  });
export const [$isMusicPolicyAccepted, musicPolicyToggled] = createToggleState({
  domain: paymentFormDomain,
});

export const [$isTrashPolicyAccepted, trashPolicyToggled] = createToggleState({
  domain: paymentFormDomain,
});
export const [$isAlcoholPolicyAccepted, alcoholPolicyToggled] =
  createToggleState({
    domain: paymentFormDomain,
  });
export const [$is3StrikesPolicyAccepted, strikesPolicyToggled] =
  createToggleState({
    domain: paymentFormDomain,
  });
export const [$isCancellationPolicyAccepted, cancellationPolicyToggled] =
  createToggleState({
    domain: paymentFormDomain,
  });

export const $allPoliciesToggled = every(
  [
    $isTermsAndConditionsAccepted,
    $isTrashPolicyAccepted,
    $isMusicPolicyAccepted,
    $isAlcoholPolicyAccepted,
    $is3StrikesPolicyAccepted,
    $isCancellationPolicyAccepted,
  ],
  true
);

const Hr = styled.div`
  height: 0;
  margin-left: -16px;
  margin-right: -16px;
  border-top: 1px solid #bdd6eb;
`;

const PaySaveMethodButton = styled(SubmitButton).attrs({ type: "button" })`
  width: 120px;
`;

const SavedMethods = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-bottom: 16px;
`;

const Fields = styled.div`
  width: 354px;

  ${Hr} {
    margin-top: 24px;
    margin-bottom: 16px;
  }
`;

const Rules = styled.div`
  margin-top: 22px;
`;
